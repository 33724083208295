import * as React from "react"
import {
    Button,
    Col,
    Form,
    FormControl,
    FormGroup,
} from "../../wrappers"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Dropdown, FormLabel, ListGroup, Row, Stack } from "react-bootstrap"
import { LabelledControl } from "../ProductEdit"
import { useEffect, useState } from "react"
import { Attribute, AttributeType, AttributeTypeKey } from "../../../models/Product"
import _ from "lodash"
import { child, get } from "firebase/database"
import { currentDatabaseRef } from "../../../config/constants"
import { PurchaseType } from "../../../models/PurchaseType"

type PriceSource = 'attribute' | 'purchase_type' | 'retail_price' | 'cost_price'

const priceSourceArray: PriceSource[]= ["attribute", "purchase_type", "retail_price", "cost_price"]

type AssetFilterType = "url" | "name"

const assetFilters: AssetFilterType[] = ["name", "url"]

class AssetFilter {
    assetFilter: AssetFilterType
    text: string

    static create(data: any): AssetFilter {
        if (!_.isNil(data.name_begins_with)) {
            return new AssetFilter("name", data.name_begins_with)
        } else {
            return new AssetFilter("url", data.url_begins_with)
        }
    }

    constructor(assetFilter: AssetFilterType, text: string) {
        this.assetFilter = assetFilter
        this.text = text
    }

    toJson() {
        switch (this.assetFilter) {
        case "url":
            return {
                url_begins_with: this.text
            }
            
        case "name":
            return {
                name_begins_with: this.text
            }
        }
    }
}

type AttributeFilter = "include" | "exclude"
const attributeFilters: AttributeFilter[]= ["include", "exclude"]

export function FranchiseModule(props: ModuleProps) {
    const [attributes, setAttributes] = useState<Attribute[]>([])
    const includeAttributes: string[] = props.moduleData.product_filter?.include_attributes ?? []
    const excludeAttributes: string[] = props.moduleData.product_filter?.exclude_attributes ?? []
    const franchiseTakers: string[] = props.moduleData.franchise_takers ?? []
    const [currentFranchiseTaker, setCurrentFranchiseTaker] = useState<string>("")
    const [attributeFilter, setAttributeFilter] = useState<AttributeFilter>(getSelectedAttributeFilter())

    function getSelectedAttributeFilter(): AttributeFilter {
        if (includeAttributes.length > 0) { return "include"}
        if (excludeAttributes.length > 0) { return "exclude"}
        return "include"
    }

    function priceAttributes() {
        const attrs: Attribute[] = []
        for (const attribute of attributes) {
            if (attribute.type.typeKey() === AttributeTypeKey.TEXT ||
            attribute.type.typeKey() === AttributeTypeKey.NUMBER) {
                attrs.push(attribute)
            }
        }
        return attrs
    }

    useEffect(() => {
        const attributes = async()=> {
            const attributesPath = `/v1/accounts/${props.role.account_id}/inventory/attributes`
            const attributesSnapshot = await get(child(currentDatabaseRef(), attributesPath))
            let attributes: Attribute[] = []
            if (attributesSnapshot.exists()) {
                _.forEach(attributesSnapshot.val(), (value) => {
                    const attribute = new Attribute(value)
                    attributes.push(attribute)
                })
            }
            setAttributes(attributes)
          }
          attributes()
    }, [])

    return (
    props.moduleData.enabled ?
        <Form>
            <FormGroup className="mb-3" style={{"paddingBottom": "20px"}}> 
            <FormLabel > Franchise takers </FormLabel>
                <Stack direction="horizontal" gap={3}>
                    <FormControl type="text" style={{width:"400px"}} placeholder="Add franchise taker id" value={currentFranchiseTaker} onChange={(value)=> {
                       setCurrentFranchiseTaker(value.currentTarget.value as string) 
                    }}></FormControl>
                    <Button disabled={currentFranchiseTaker.length === 0} style={{width:"200px"}} onClick={() => {
                        const current = _.cloneDeep(franchiseTakers)
                        if (currentFranchiseTaker.length > 0) {
                            current.push(currentFranchiseTaker)
                            props.updateConfiguration(existing => {
                                existing["franchise_takers"] = current
                                return existing
                            })
                            setCurrentFranchiseTaker("")
                        }
                    }}> Add franchise taker</Button>
                </Stack>
                <ListGroup style={{ display: "inline-block", "paddingTop": "20px"}}>
                    {franchiseTakers.map((item) => (
                        <ListGroup.Item
                                key={item}
                                className="position-relative"
                                style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "auto",
                                paddingRight: "40px",
                                }}
                            >
                            {item}
                            <Button
                            variant="danger"
                            className="position-absolute"
                            onClick={() => {
                                props.updateConfiguration(existing => {
                                    existing["franchise_takers"] = franchiseTakers.filter(franchiseTaker => franchiseTaker != item)
                                    return existing
                                })
                            }}
                            style={{
                                top: "5px",
                                right: "5px",
                                border: "none",
                                background: "none",
                                color: "gray",
                                fontSize: "1rem",
                                cursor: "pointer",
                            }}
                            >
                                &times;
                            </Button>
                    </ListGroup.Item>
                    ))}
                </ListGroup>
            </FormGroup>
            <FormGroup className="mb-3" as={Row} style={{"paddingBottom": "10px"}}>
            <Col sm={2} > 
                <FormLabel> Attribute filters </FormLabel>
            </Col>
            <Col>
                <Stack direction="horizontal" gap={1}>
                <Dropdown onSelect={selectedId => {
                    const attributeFilter = selectedId as AttributeFilter
                    setAttributeFilter(attributeFilter)
                } }>
                    <Dropdown.Toggle style={{ width: "200px"}}size="sm" variant={"outline-primary" }> {attributeFilter === "include" ? "Include attributes" : "Exclude attributes" } </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Array.from(attributeFilters).map((filter) => {
                            return <Dropdown.Item
                                active={(filter === attributeFilter)}
                                key={filter}
                                eventKey={filter}>{ filter === "include" ? "Include attributes" : "Exclude attributes"}
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown> 
                {
                    attributeFilter === "include" ?
                    <SelectAttributes attributes={attributes} selectedAttributes={includeAttributes} selectedAttributeUpdate={(attributes => {
                        props.updateConfiguration(existing => {
                            const productFilter = _.cloneDeep(existing["product_filter"]) ?? {}
                            productFilter["include_attributes"] = attributes
                            delete productFilter["exclude_attributes"]
                            return existing["product_filter"] = productFilter
                        })
                        })}></SelectAttributes>
                    : <SelectAttributes attributes={attributes} selectedAttributes={excludeAttributes} selectedAttributeUpdate={(attributes => {
                        props.updateConfiguration(existing => {
                            const productFilter = _.cloneDeep(existing["product_filter"]) ?? {}
                            productFilter["exclude_attributes"] = attributes
                            delete productFilter["include_attributes"]
                            return existing["product_filter"] = productFilter
                        })
                    })}></SelectAttributes>
                }
                </Stack>
            </Col>
            
            </FormGroup>
            
            <PriceSource attributes={priceAttributes()} priceType={"retail"} role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} ></PriceSource>
            <PriceSource attributes={priceAttributes()} priceType={"cost"} role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} ></PriceSource>

            <AssetFilterView role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} ></AssetFilterView>
        </Form>
    : null
    )
}

type PriceSourceType = "retail" | "cost"

type AssetInclusionType = "include_assets" | "exclude_assets"

function AssetFilterView(props: ModuleProps) {
    const [assetInclusionFilter, setAssetInclusionFilter] = useState<AssetFilterType>()
    const [assetFilterText, setAssetFilterText] = useState<string>("")
    const [inclusionType, setInclusionType] = useState<AssetInclusionType>(!_.isNil(props.moduleData.product_filter?.exclude_assets) ? "exclude_assets" : "include_assets")
    const assetFilter = getAssetFilters()

    function getAssetFilters(): AssetFilter[] {
        let assets: any[] = []
        if (inclusionType === "include_assets") {
            assets = props.moduleData.product_filter?.include_assets ?? []
        } else {
            assets = props.moduleData.product_filter?.exclude_assets ?? []
        }
        return assets.map(value => {
            return AssetFilter.create(value)
        })
    }

    return <FormGroup className="mb-3">
        <Stack direction="horizontal" gap={2} style={{"paddingBottom": "20px"}}>
            <FormLabel className="m-0"> {"Assets to:" }  </FormLabel>
            <Dropdown onSelect={selectedId => {
                    const assetInclusionType = selectedId as AssetInclusionType
                    setInclusionType(assetInclusionType)
                } }>
                    <Dropdown.Toggle size="sm" variant={"primary" }> {inclusionType === "include_assets" ? "Include" : "Exclude" } </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Array.from(["include_assets", "exclude_assets"]).map((filter) => {
                            return <Dropdown.Item
                                active={(filter === assetInclusionFilter)}
                                key={filter}
                                eventKey={filter}>{ filter === "include_assets" ? "Include" : "Exclude"}
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
        <Stack direction="horizontal" gap={3}>
            <Dropdown onSelect={selectedId => {
                const assetFilter = selectedId as AssetFilterType
                setAssetInclusionFilter(assetFilter)
            } }>
                <Dropdown.Toggle size="sm" variant={"outline-primary" }> {getAssetFilterTitle() } </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Array.from(assetFilters).map((filter, index) => {
                        return <Dropdown.Item
                            active={(filter === assetInclusionFilter)}
                            key={filter}
                            eventKey={filter}>{ filter === "name" ? "Name begins with" : "URL begins with"}
                        </Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <FormControl style={{ width: "300px", "paddingBottom": "5px"}} type="text" placeholder="Add asset filter" value={assetFilterText} onChange={(value) => {
                setAssetFilterText(value.currentTarget.value as string)
            } }></FormControl>
            <Button 
                style={{ width: "250px" }}
                disabled={(_.isNil(assetInclusionFilter) || assetFilterText.length === 0)}
                onClick={() => {
                    const current = _.cloneDeep(assetFilter)
                    if (assetFilterText.length > 0) {
                        if (_.isNil(assetInclusionFilter)) { return} 
                        const assetFilter = new AssetFilter(assetInclusionFilter, assetFilterText)
                        current.push(assetFilter)

                        props.updateConfiguration(existing => {
                            const productFilter = _.cloneDeep(existing["product_filter"]) ?? {}
                            productFilter[inclusionType] = current.map(value => value.toJson())
                            if (inclusionType === "exclude_assets") {
                                delete productFilter["include_assets"]
                            } else {
                                delete productFilter["exclude_assets"]
                            }
                            existing["product_filter"] = productFilter
                            return existing
                        })
                        setAssetFilterText("")
                    }
            } }> Add asset filter</Button>
        </Stack>
        <ListGroup style={{ display: "inline-block", "paddingTop": "20px" }}>
            {assetFilter.map((item, index) => (
                <ListGroup.Item
                    key={item.text + index}
                    className="position-relative"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "auto",
                        paddingRight: "40px",
                    }}
                >
                    {(item.assetFilter === "name" ? "Name begins with: " : "URL begins with: ") + item.text}
                    <Button
                        variant="danger"
                        className="position-absolute"
                        onClick={() => {
                            props.updateConfiguration(existing => {
                                const productFilter = _.cloneDeep(existing["product_filter"]) ?? {}
                                productFilter[inclusionType] = assetFilter.filter(assetFilter => assetFilter != item).map(value => value.toJson())
                                existing["product_filter"] = productFilter
                                return existing
                            })
                        } }
                        style={{
                            top: "5px",
                            right: "5px",
                            border: "none",
                            background: "none",
                            color: "gray",
                            fontSize: "1rem",
                            cursor: "pointer",
                        }}
                    >
                        &times;
                    </Button>
                </ListGroup.Item>
            ))}
        </ListGroup>
    </FormGroup>

    function getAssetFilterTitle(): string {
        if (_.isNil(assetInclusionFilter)) { return "Select" }
        return assetInclusionFilter === "name" ? "Name begins with" : "URL begins with"
    }
}

function getPathFromPriceType(type: PriceSourceType): string {
    switch (type) {
        case "cost": { return "cost_price_source"}
        case "retail": { return "retail_price_source"}
    } 
}

interface PriceSourceProps extends ModuleProps {
    attributes: Attribute[]
    priceType: PriceSourceType
}

function PriceSource(props: PriceSourceProps) {
    function getSelectedPriceSource(): PriceSource | undefined {
        const data = props.moduleData
        const path = getPathFromPriceType(props.priceType)
        const productFilter = data["product_filter"]
        if (_.isNil(productFilter)) { return undefined }
        const priceSource = productFilter[path]
        if (!_.isNil(priceSource?.attribute)) { return "attribute"}
        if (!_.isNil(priceSource?.purchase_type)) { return "purchase_type"}
        if (!_.isNil(priceSource?.cost_price)) { return "cost_price" }
        if (!_.isNil(priceSource?.retail_price)) { return "retail_price"}
        return undefined
    }
    const [selectedPriceSource, setSelectedPriceSource] = useState<PriceSource | undefined>(getSelectedPriceSource())
    const [purchaseTypes, setPurchaseTypes] = useState<PurchaseType[]>([])

    useEffect(() => {
          const purchaseTypes = async()=> {
            const purchaseTypePath = `/v1/accounts/${props.role.account_id}/configuration/purchase_types/available`
            const purchaseTypeSnapshot = await get(child(currentDatabaseRef(), purchaseTypePath))
            let purchaseTypes: PurchaseType[] = []
            if (purchaseTypeSnapshot.exists()) {
                _.forEach(purchaseTypeSnapshot.val(), (value) => {
                    const purchaseType = new PurchaseType(value)
                    purchaseTypes.push(purchaseType)
                })
            }
            setPurchaseTypes(purchaseTypes)
          }
          purchaseTypes()
    }, [])

    return (
        <div>
            <FormGroup className="mb-3" as={Row}>
                <Col sm={2} > {getDescription()} </Col>
                <Col>
                    <Stack direction="horizontal" gap={1}>
                    <LabelledControl>
                        <Dropdown onSelect={selectedId => {
                            const retailSelection = selectedId as PriceSource
                            if (retailSelection === selectedPriceSource) { 
                                setSelectedPriceSource(undefined)
                                props.updateConfiguration(existing => {
                                    const productFilter = existing["product_filter"]
                                    if (_.isNil(productFilter)) { return }
                                    if (!_.isNil(productFilter[getPathFromPriceType(props.priceType)])) {
                                        delete productFilter[getPathFromPriceType(props.priceType)]
                                    }
                                    return existing["product_filter"] = productFilter
                                })
                            } else {
                                setSelectedPriceSource(retailSelection)
                            }
                        } }>
                            <Dropdown.Toggle style={{ width: "200px" }} size="sm" variant={!_.isNil(selectedPriceSource) && (selectedPriceSource === "retail_price" || selectedPriceSource === "cost_price") ? "primary" : "outline-primary"}> {getPriceSelectionTitle(props.priceType, selectedPriceSource) ?? "Select"} </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {Array.from(priceSourceArray).map((retailPriceSelection) => {
                                    if (props.priceType === "retail" && retailPriceSelection === "cost_price") { return null }
                                    if (retailPriceSelection === "purchase_type" && purchaseTypes.length === 0) { return null }
                                    if (retailPriceSelection === "attribute" && props.attributes.length === 0) { return null }
                                    return <Dropdown.Item
                                        active={(selectedPriceSource === retailPriceSelection)}
                                        key={retailPriceSelection}
                                        eventKey={retailPriceSelection}>{getPriceSelectionTitle(props.priceType, retailPriceSelection)}
                                    </Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </LabelledControl>
                    <PriceSourceSelection priceType={props.priceType} moduleData={props.moduleData} role={props.role} updateConfiguration={props.updateConfiguration} attributes={props.attributes} purchaseTypes={purchaseTypes} priceSource={selectedPriceSource}></PriceSourceSelection>

                    </Stack>
                </Col>
            </FormGroup>
        </div>
    )

    function getDescription(): string {
        switch (props.priceType) {
            case "cost": return "Cost price source"
            case "retail": return "Retail price source"
        }
    }
}

interface PriceSourceSelectionProps extends ModuleProps {
    attributes: Attribute[]
    purchaseTypes: PurchaseType[]
    priceSource?: PriceSource
    priceType: PriceSourceType
}

function PriceSourceSelection(props: PriceSourceSelectionProps){
    useEffect(() => {
        if (props.priceSource === "retail_price") {
            props.updateConfiguration(existing => {
                const productFilter = existing["product_filter"] ?? {}
                productFilter[getPathFromPriceType(props.priceType)] = { retail_price: true }
                existing["product_filter"] = productFilter
                return existing
            })
        }
        if (props.priceSource === "cost_price") {
            props.updateConfiguration(existing => {
                const productFilter = existing["product_filter"] ?? {}
                productFilter[getPathFromPriceType(props.priceType)] = { cost_price: true }
                existing["product_filter"] = productFilter
                return existing
            })
        }

    }, [props.priceSource])

    if (_.isNil(props.priceSource)) { return <div></div> }
    switch (props.priceSource) {
        case "attribute": {
            const selectedAttribute = props.moduleData.product_filter?.[getPathFromPriceType(props.priceType)]?.attribute
            return (<AttributeSource attributes={props.attributes} selectedAttribute={selectedAttribute} newSelectedAttribute={(selectedAttribute) => {
                props.updateConfiguration(existing => {
                    const productFilter = existing["product_filter"] ?? {}
                    const attribute = { attribute: selectedAttribute }
                    productFilter[getPathFromPriceType(props.priceType)] = attribute
                    existing["product_filter"] = productFilter
                    return existing
                })
                
            }}></AttributeSource>)
        }
        case "purchase_type": {
            const selectedPurchaseType = props.moduleData.product_filter?.[getPathFromPriceType(props.priceType)]?.purchase_type
            return (<PurchaseTypeSource purchaseTypes={props.purchaseTypes} selectedPurchaseType={selectedPurchaseType} newSelectedPurchaseType={(selectedPurchaseType) => {
                props.updateConfiguration(existing => {
                    const productFilter = existing["product_filter"] ?? {}
                    const purchaseType = { purchase_type: selectedPurchaseType }
                    productFilter[getPathFromPriceType(props.priceType)] = purchaseType
                    existing["product_filter"] = productFilter
                    return existing
                })
                
            }}></PurchaseTypeSource>)
        }
        
        case "retail_price":  return <div></div>
        case "cost_price":  return <div></div>
    }
    return <div></div>
}

interface AttributeSource {
    attributes: Attribute[]
    selectedAttribute?: string
    newSelectedAttribute: (attribute: string) => void
}

function AttributeSource(props: AttributeSource) {
    
    return (
        <LabelledControl>
            <Dropdown onSelect={selectedId => {
                if (_.isNil(selectedId)) { return }
                props.newSelectedAttribute(selectedId)
            } }>
                <Dropdown.Toggle size="sm" variant={!_.isNil(props.selectedAttribute) ? "primary" : "outline-primary"}> {attributeTitle(props.selectedAttribute, props.attributes) ?? "Select attribute"} </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Array.from(props.attributes).map((attribute) => {
                        return <Dropdown.Item
                            active={props.selectedAttribute === attribute.id}
                            key={attribute.id}
                            eventKey={attribute.id}>{attribute.name.localized(null)}
                        </Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </LabelledControl>
    )
    
    function attributeTitle(selected: string | undefined, attributes: Attribute[]): string | undefined {
        const selectedAttribute = attributes.find(attribute => attribute.id === selected)
        return selectedAttribute?.name.localized(null)
    }
}

interface PurchaseTypeSource {
    purchaseTypes: PurchaseType[]
    selectedPurchaseType?: string
    newSelectedPurchaseType: (purchaseType: string) => void
}

function PurchaseTypeSource(props: PurchaseTypeSource) {
    return (
            <LabelledControl>
                <Dropdown onSelect={selectedId => {
                    if (_.isNil(selectedId)) { return }
                    props.newSelectedPurchaseType(selectedId)
                } }>
                    <Dropdown.Toggle size="sm" variant={!_.isNil(props.selectedPurchaseType) ? "primary" : "outline-primary"}> {purchaseTypeTitle(props.selectedPurchaseType, props.purchaseTypes) ?? "Select purchase type"} </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Array.from(props.purchaseTypes).map((purchaseType) => {
                            return <Dropdown.Item
                                active={props.selectedPurchaseType === purchaseType.identifier}
                                key={purchaseType.identifier}
                                eventKey={purchaseType.identifier}>{purchaseType.name.localized(null)}
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </LabelledControl>
    )
    
    function purchaseTypeTitle(selected: string | undefined, purchaseTypes: PurchaseType[]): string | undefined {
        const selectedPurchaseType = purchaseTypes.find(purchaseType => purchaseType.identifier === selected)
        return selectedPurchaseType?.name.localized(null)
    }
}

function getPriceSelectionTitle(type: PriceSourceType, retailPriceSelection?: PriceSource): string | undefined {
    if (_.isNil(retailPriceSelection)) { return undefined }
    switch (retailPriceSelection) {
        case "attribute": return "Attribute"
        case "purchase_type": return "Purchase type"
        case "retail_price": return "Retail price"
        case "cost_price": return "Cost price"
    }
}

interface AttributeSelection {
    attributes: Attribute[]
    selectedAttributes: string[]
    selectedAttributeUpdate: (attributes: string[]) => void
}

function SelectAttributes(props: AttributeSelection) {
    return (

            <Dropdown onSelect={selectedId => {
                if (_.isNil(selectedId)) { return} 
                const attributeSelected = props.attributes.find(attribute => attribute.id === selectedId)
                if (_.isNil(attributeSelected)) { return }

                const newArray = _.cloneDeep(props.selectedAttributes)
                const indexToDeleted = newArray.findIndex(id => id === selectedId)
                if (indexToDeleted != -1) {
                    newArray.splice(indexToDeleted, 1)
                } else {
                    newArray.push(attributeSelected.id)
                }
                props.selectedAttributeUpdate(newArray)
            } }>
                <Dropdown.Toggle size="sm" variant={props.selectedAttributes.length > 0 ? "primary" : "outline-primary"}> {getAttributesDescription(props.attributes, props.selectedAttributes)} </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Array.from(props.attributes).map((attribute) => {
                        return <Dropdown.Item
                            active={props.selectedAttributes.find(id => id === attribute.id) != null}
                            key={attribute.id}
                            eventKey={attribute.id}>{attribute.name.localized(null)}
                        </Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>

    )


    function getAttributesDescription(allAttributes: Attribute[], selectedAttributes: string[]): string {
        if (selectedAttributes.length === 0) { return "Select attributes" }
        else {
            let selectedAttributeValues = allAttributes.filter(attribute => selectedAttributes.includes(attribute.id))
            return selectedAttributeValues.map(attribute => attribute.name.localized(null)).join(", ")
        }
    }
}

