import dayjs from "dayjs"
import { filterDescription } from "../StockCountList/StockCountOpenModal"
import { Button, Modal } from "react-bootstrap"
import { StockCountRequest } from "../../../models/StockCountRequest"
import { Attribute } from "../../../models/Product"
import React from "react"
import * as _ from "lodash"
import { FormattedTime } from "react-intl"

interface StockCountRequestModalProps {
    request: StockCountRequest
    onComplete: () => void
    openStockCount: (request: StockCountRequest) => void
    cancelStockCount: (request: StockCountRequest) => void
    attributes?: _.Dictionary<Attribute>
    hasCurrentCount: boolean
}

export function StockCountRequestModal(props: StockCountRequestModalProps) {
    const thisMorning = dayjs().startOf("day").toDate()

    return <Modal size="lg" show={true} onHide={() => props.onComplete()}>
        <Modal.Header>
            <Modal.Title>
                {props.request.name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <dl>
                <dt>Created</dt>
                <dd>
                    <FormattedTime
                        value={props.request.creationDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </dd>
            </dl>
            <dl>
                <dt>Due</dt>
                <dd style={{ color: (props.request.dueDate < thisMorning) ? "#FF0000" : "black" }}>
                    <FormattedTime
                        value={props.request.dueDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </dd>
            </dl>
            <dl>
                <dt>Stock count request description</dt>
                <dd className="mb-3" dangerouslySetInnerHTML={{ __html: filterDescription(props.request.filter, "stock_count_request", props.attributes).join("") }} />
            </dl>
        </Modal.Body>
        <Modal.Footer>
            {props.hasCurrentCount && <div>A stock count is currently in progress. Stop that before starting a new one.</div>}
            <Button variant="success" disabled={props.hasCurrentCount} onClick={() => { props.openStockCount(props.request) }}>Start count</Button>
            <Button variant="danger" disabled={props.hasCurrentCount} onClick={() => { props.cancelStockCount(props.request) }}>Cancel request</Button>
            <Button variant="secondary" onClick={() => props.onComplete()}>Close dialog</Button>
        </Modal.Footer>
    </Modal>
}

