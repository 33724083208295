import * as React from "react"
import {
    Button,
    Col,
    Grid,
    Card,
    Row
} from "../wrappers"
import { LineItemSalesReport } from "../../reports/LineItemSalesReport"
import { PageState } from "../PageState"
import { PaymentTypeSalesReport } from "../../reports/PaymentTypeSalesReport"
import { ProductCatalogService } from "../../services/ProductCatalogService"
import { ProductStatInventoryReportBuilder } from "../../services/ProductStatInventoryReportBuilder"
import { currentDatabaseRef } from "../../config/constants"
import { ReportComponent } from "../ReportComponent"
import { StockReportBuilder } from "../../services/StockReportBuilder"
import { RoleShopProps, withShop } from "../../routes"
import * as _ from "lodash"
import { StockCountReportModal, StockReportFilter } from "./StockCountReports/StockCountReportModal"
import { child, get } from "firebase/database"

const FileDownload = require("js-file-download")

interface ReportsState {
    account_name?: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    shop_name?: string,
    focusedInput?: any
    reportMessage?: string
    showStockReportSheet: boolean
    lastCountedOnlyUncounted: boolean
}

class Reports extends React.Component<RoleShopProps, ReportsState> {

    // Props

    private productCatalogService = new ProductCatalogService()

    // Lifecycle

    constructor(props: RoleShopProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            showStockReportSheet: false,
            lastCountedOnlyUncounted: false
        }
    }

    // Component

    async componentDidMount() {
        this.setState({ loaded: false })

        const accountNameSnapshot = await get(child(currentDatabaseRef(), `v1/account_index/${this.props.role.account_id}/name`))
        const shopNameSnapshot = await get(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/shop_index/${this.props.shop}/name`))

        const update: any = { loaded: true }
        if (accountNameSnapshot.exists()) {
            update.account_name = accountNameSnapshot.val()
        }
        if (shopNameSnapshot.exists()) {
            update.shop_name = shopNameSnapshot.val()
        }

        this.setState(update)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} dirty={this.state.dirty} customMessage={this.state.reportMessage} typeName="reports">
                <Card className="my-4">
                    <Card.Header>
                        Sales history and current inventory per product
                    </Card.Header>
                    <Card.Body>
                        <Grid>
                            <Row>
                                Choose between CSV or PDF document.
                            </Row>
                            <Row>
                                The column &quot;Est. days until out of stock&quot; is the current stock count divided by the average daily number of items sold during the last 7 days.
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="secondary" className="px-4" onClick={this.buildCSVStatsAndInventoryReport}>CSV</Button>
                                    <Button variant="secondary" className="mx-4 px-4" onClick={this.buildPDFStatsAndInventoryReport}>PDF</Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Card.Body>
                </Card>

                <ReportComponent shop={this.props.shop} role={this.props.role} report={new PaymentTypeSalesReport()} />

                <ReportComponent shop={this.props.shop} role={this.props.role} report={new LineItemSalesReport()} />

                <Card className="my-4">
                    <Card.Header>
                        Stock report - this report extracts all your current stock values
                    </Card.Header>
                    <Card.Body>
                        <Grid>
                            <Row>
                                <Col>
                                    <Button variant="secondary" onClick={this.buildStockReport}>CSV</Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Card.Body>
                </Card>

                <Card className="my-4">
                    <Card.Header>
                        Last counted stock report - this report shows when each item was last counted after a selected date
                    </Card.Header>
                    <Card.Body>
                        <Grid>
                            <Row>
                                <Col sm={2}>
                                    <Button variant="secondary" onClick={() => { this.setState({ showStockReportSheet: true, lastCountedOnlyUncounted: false }) }}>Last counted</Button>
                                </Col>
                                <div> This displays all counted and uncounted stock values from the specified date</div>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm={2}>
                                    <Button variant="secondary" onClick={() => { this.setState({ showStockReportSheet: true, lastCountedOnlyUncounted: true }) }}>Only uncounted</Button>
                                </Col>
                                <div> This displays only uncounted stock values from the specified date</div>
                            </Row>
                        </Grid>
                    </Card.Body>
                </Card>
                {
                    this.state.showStockReportSheet && <StockCountReportModal
                        reportType={this.state.lastCountedOnlyUncounted ? "not_counted_since_date" : "counted_since_date"}
                        mode="last_counted_shop"
                        stockLocation={""}
                        role={this.props.role}
                        openNew={async (filter: StockReportFilter) => { await this.buildLastCountedStockReport(filter); }}
                        cancel={() => { this.setState({ showStockReportSheet: false }) }}
                    />
                }

                <Card className="my-4">
                    <Card.Header>
                        Stock event report - this report extracts the stock events for today
                    </Card.Header>
                    <Card.Body>
                        <Grid>
                            <Row>
                                <Col>
                                    <Button variant="secondary" onClick={() => this.buildStockMoveReport("all")}>All</Button>&nbsp;
                                    <Button variant="secondary" onClick={() => this.buildStockMoveReport("received")}>Received</Button>&nbsp;
                                    <Button variant="secondary" onClick={() => this.buildStockMoveReport("removal")}>Removals</Button>&nbsp;
                                    <Button variant="secondary" onClick={() => this.buildStockMoveReport("sale")}>Sales</Button>&nbsp;
                                    <Button variant="secondary" onClick={() => this.buildStockMoveReport("return")}>Returns</Button>&nbsp;
                                </Col>
                            </Row>
                        </Grid>
                    </Card.Body>
                </Card>

            </PageState>
        )
    }

    getDateOneYearAgo(): Date {
        let now = new Date()
        now.setFullYear(now.getFullYear() - 1)
        return now
    }

    // Methods

    buildCSVStatsAndInventoryReport = async () => {
        this.setState({ publishing: true })

        const service = new ProductStatInventoryReportBuilder(this.props.role.account_id, this.props.shop, this.state.account_name, this.state.shop_name)
        const report = await service.buildCSVStatsAndInventoryReport(";", ",")
        FileDownload(report, `${this.buildStatsAndInventoryReportFileName()}.csv`)

        this.setState({ publishing: false })
    }

    buildPDFStatsAndInventoryReport = async () => {
        this.setState({ publishing: true })

        const service = new ProductStatInventoryReportBuilder(this.props.role.account_id, this.props.shop, this.state.account_name, this.state.shop_name)
        await service.buildPDFStatsAndInventoryReport()

        this.setState({ publishing: false })
    }

    buildStatsAndInventoryReportFileName = (): string => {
        // just using the same string as pdf header
        const service = new ProductStatInventoryReportBuilder(this.props.role.account_id, this.props.shop, this.state.account_name, this.state.shop_name)
        return service.buildDocumentName(true)
    }

    buildStockReport = async () => {
        const message = this.stockReportMessage(0, 0)
        this.setState({ reportMessage: message })
        const builder = new StockReportBuilder(this.props.role.account_id, this.props.shop, this.productCatalogService)
        const report = await builder.buildStockReport((loaded: number, aggregated: number) => {
            this.setState({ reportMessage: this.stockReportMessage(loaded, aggregated) })
        })
        const fileName = report[1]
        FileDownload(report[0], `${fileName}.csv`)
        this.setState({ reportMessage: undefined })
    }

    buildLastCountedStockReport = async (filter: StockReportFilter) => {
        this.setState({ showStockReportSheet: false })
        const displayOnlyUncounted = this.state.lastCountedOnlyUncounted
        const message = this.stockReportMessage(0, 0)
        this.setState({ reportMessage: message })
        const builder = new StockReportBuilder(this.props.role.account_id, this.props.shop, this.productCatalogService)
        const report = await builder.buildLastCountedStockReport(filter, displayOnlyUncounted, (loaded: number, aggregated: number) => {
            this.setState({ reportMessage: this.stockReportMessage(loaded, aggregated) })
        })
        const fileName = report[1]
        FileDownload(report[0], `${fileName}.csv`)
        this.setState({ reportMessage: undefined })
    }

    buildStockMoveReport = async (type: "all" | "removal" | "received" | "sale" | "return") => {
        const message = this.stockReportMessage(0, 0)
        this.setState({ reportMessage: message })
        const builder = new StockReportBuilder(this.props.role.account_id, this.props.shop, this.productCatalogService)
        const report = await builder.buildStockMoveReport(type, (loaded: number, aggregated: number) => {
            this.setState({ reportMessage: this.stockReportMessage(loaded, aggregated) })
        })
        const fileName = report[1]
        FileDownload(report[0], `${fileName}.csv`)
        this.setState({ reportMessage: undefined })
    }

    stockReportMessage(loaded: number, aggregated: number): string {
        let result = "Generating report - "
        if (aggregated) {
            result += `created ${aggregated} lines`
        } else {
            result += `loaded ${loaded} products`
        }
        return result
    }
}

export default withShop(Reports)
