import * as React from "react"
import {
    Navigate,
    useOutletContext,
    createBrowserRouter,
    RouterProvider
} from "react-router-dom"
// import logo from "./logo.svg"
import "bootstrap/dist/css/bootstrap.css"
// import "bootstrap/dist/css/bootstrap-theme.css"
// import "react-dates/lib/css/_datepicker.css"
import "./assets/css/app.css"
import "@nosferatu500/react-sortable-tree/style.css"

import { ShopRoute, AccountRoute, withRole, withShop, StockLocationRoute, withRoleRouterFunc } from "./routes"
import AccessDenied from "./components/protected/AccessDenied"
import AttributeEdit from "./components/protected/AttributeEdit"
import AttributeList from "./components/protected/AttributeList"
import AttributeGroupList from "./components/protected/AttributeGroupList"
import AttributeGroupEdit from "./components/protected/AttributeGroupEdit"
import BudgetEdit from "./components/protected/BudgetEdit"
import CashierRoles from "./components/protected/Configuration/CashierRoles"
import CashierList from "./components/protected/CashierList/CashierList"
import Configuration from "./components/protected/Configuration"
import EmailConfiguration from "./components/protected/Advanced/EmailConfiguration"
import ExportIntegrationQueue from "./components/protected/Advanced/ExportIntegrationQueue"
import ExportIntegrations from "./components/protected/Advanced/ExportIntegrations"
import FacetList from "./components/protected/Facets/FacetList"
import FacetEdit from "./components/protected/Facets/FacetEdit"
import GroupEdit from "./components/protected/GroupEdit"
import GroupList from "./components/protected/GroupList"
import ImportIntegrationQueue from "./components/protected/Advanced/ImportIntegrationQueue"
import ImportIntegrations from "./components/protected/Advanced/ImportIntegrations"
import RestIntegration from "./components/protected/Advanced/RestIntegration"
import IntegrationNotificationConfiguration from "./components/protected/Advanced/IntegrationNotificationConfiguration"
import Login from "./components/Login"
import ModulesConfiguration from "./components/protected/Configuration/ModulesConfiguration"
import { Overview, ShopOverview } from "./components/protected/Overview"
import ProductEdit from "./components/protected/ProductEdit"
import ProductImport from "./components/protected/ProductImport"
import ProductList from "./components/protected/ProductList/ProductList"
import ProductRuleList from "./components/protected/ProductRule/ProductRuleList"
import ProductRuleEdit from "./components/protected/ProductRule/ProductRuleEdit"
import RecommendationCategoryEdit from "./components/protected/RecommendationCategoryEdit"
import RecommendationsMaintainance from "./components/protected/RecommendationsMaintainance"
import Register from "./components/Register"
import RegisterCloseStatementList from "./components/protected/RegisterCloseStatementList"
import RegisterCloseStatementView from "./components/protected/RegisterCloseStatementView"
import Reports from "./components/protected/Reports"
import ReserveAndCollectDemo from "./components/protected/ReserveAndCollect/ReserveAndCollectDemo"
import RoleSelection from "./components/protected/RoleSelection"
import RuleEdit from "./components/protected/DiscountRules/RuleEdit"
import RuleList from "./components/protected/DiscountRules/RuleList"
import RuntimeIntegrations from "./components/protected/Advanced/RuntimeIntegrations"
import StockImport from "./components/protected/StockImport"
import CustomReports from "./components/protected/CustomReports"

// import * as en from "react-intl/locale-data/en"
// import { addLocaleData } from "react-intl"
// import * as fr from "react-intl/locale-data/fr"
// import * as es from "react-intl/locale-data/es"
// import * as da from "react-intl/locale-data/da"
// import * as de from "react-intl/locale-data/de"
import * as _ from "lodash"
import StockRemovalReasonCodeEdit from "./components/protected/Configuration/StockRemovalReasonCodeEdit"
import Customers from "./components/protected/Customers"
import CustomerDetail from "./components/protected/CustomerDetail"
import CustomerLoyaltyPointsLog from "./components/protected/LoyaltyPointLog"
import MarketList from "./components/protected/MarketList"
import MarketEdit from "./components/protected/MarketEdit"
import TaxEdit from "./components/protected/TaxEdit"
import ThemeConfiguration from "./components/protected/ThemeConfiguration"
import ConfigurableFrontPage from "./components/protected/ConfigurableFrontPage/ConfigurableFrontPage"
import ProductOrganization from "./components/protected/ProductOrganization/ProductOrganization"
import SaleView from "./components/protected/SaleView"
import SalesList from "./components/protected/SalesList"
import ShopConfiguration from "./components/protected/ShopConfiguration"
import ShopEdit from "./components/protected/ShopEdit"
import ShopList from "./components/protected/ShopList"
import StockLocationEdit from "./components/protected/StockLocationEdit"
import StockList from "./components/protected/StockList/StockList"
import StockCountCurrent from "./components/protected/StockCountCurrentView/StockCountCurrent"
import StockCountList from "./components/protected/StockCountList/StockCountList"
import StockCountPast from "./components/protected/StockCountPast/StockCountPast"
import UserManagement from "./components/protected/UserManagement"
import TagEdit from "./components/protected/TagEdit"
import TagList from "./components/protected/TagList"
import ExpenseList from "./components/protected/ExpenseList"
import ExpenseEdit from "./components/protected/ExpenseEdit"
import GiftcardList from "./components/protected/GiftcardList"
import GiftcardTypeList from "./components/protected/GiftcardTypeList/GiftcardTypeList"
import GiftcardTypeDetail from "./components/protected/GiftcardTypeDetail/GiftcardTypeDetail"
import GiftcardPDFTemplateEditor from "./components/protected/TemplateEditors/GiftcardTemplateEditor/GiftcardPDFTemplateEditor"
import EmailTemplateEditor from "./components/protected/TemplateEditors/EmailTemplateEditor/EmailTemplateEditor"
import StockCountRequests from "./components/protected/StockCountRequests/StockCountRequests"
import StockCountReports from "./components/protected/StockCountReports/StockCountReports"
import { AppLayout, DeselectStockLocationCallback, SelectStockLocationCallback, useAppContext } from "./AppLayout"

// addLocaleData([...en, ...fr, ...es, ...da, ...de])

const RoleOverview = withRole(Overview)
const RoleShopOverview = withShop(ShopOverview)

const router = createBrowserRouter([
    {
        element: <AppLayout/>, 
        children: [
            { path:"/", element: <LandingPageWrapper/>},
            { 
                path: "/shop/:shopKey", element: <ShopRoute />,
                children: [
                    { path: "",  element:<RoleShopOverview /> },
                    { path: "cashiers",  element:<CashierList /> },
                    { path: "register_close_statement/:id",  element:<RegisterCloseStatementView /> },
                    { path: "register_close_statements",  element:<RegisterCloseStatementList /> },
                    { path: "sales/:saleKey",  element:<SaleView /> },
                    { path: "sales",  element:<SalesList /> },
                    { path: "configuration",  element:<ShopConfiguration /> },
                    // <ProductEditRoute path="/shop_product/:productKey/new" shop={this.shopId} resolver={this} /> This was commented out, don't know why?
                    // <ProductEditRoute path="/shop_product/:productKey" shop={this.shopId} resolver={this} /> This was commented out, don't know why?
                    { path: "edit",  element:<ShopEdit /> },
                    { path: "budget",  element:<BudgetEdit /> },
                    { path: "reserve_and_collect_demo",  element:<ReserveAndCollectDemo /> },
                    { path: "reports",  element:<Reports /> },
                    { path: "products",  element:<ProductList /> },
                ]
            },
            { 
                path: "stock_location/:stockLocationKey", element: <StockLocationRoute/>,
                children: [
                    { path: "edit", element: <StockLocationEdit />},
                    { path: "stock", element: <StockListWrapper />},
                    { path: "stock_counts", element: <StockCountList />},
                    { path: "stock_count/past/:stockCountId", element: <StockCountPast />},
                    { path: "stock_count/current/:stockCountId", element: <StockCountCurrent />},
                    { path: "stock_import", element: <StockImport />},
                ]
            },
            { path: "/login", element: <LoginWrapper/> },
            { path: "/register", element: <Register/> },
            { 
                path: "", element: <AccountRoute />,
                children: [
                    { path: "/product_import", element: <ProductImport />},
                    { path: "/products/:productKey", element: <ProductEdit />},
                    { path: "/customers", element: <Customers />},
                    { path: "/overview", element: <RoleOverview />},
                    { path: "/configuration", element: <Configuration />},
                    { path: "/configuration/gift_card_types", element: <GiftcardTypeList />},
                    { path: "/configuration/gift_card_types/:typeId", element: <GiftcardTypeDetail />},
                    { path: "/configuration/gift_card_types/:typeId/pdf", element: <GiftcardPDFTemplateEditor />},
                    { path: "/configuration/gift_card_types/:typeId/email", element: <EmailTemplateEditor />},
                    { path: "/import_integrations", element: <ImportIntegrations />},
                    { path: "/export_integrations", element: <ExportIntegrations />},
                    { path: "/customers/:id/new", element: <CustomerDetail isNew={true} />},
                    { path: "/customers/:id/loyalty_points_log", element: <CustomerLoyaltyPointsLog />},
                    { path: "/customers/:id", element: <CustomerDetail isNew={false}/> },
                    { path: "/import_integration/:integrationType/:integrationKey", element: <ImportIntegrationQueue />}, 
                    { path: "/export_integration/:integrationType/:integrationKey", element: <ExportIntegrationQueue />}, 
                    { path: "/runtime_integrations", element: <RuntimeIntegrations />}, 
                    { path: "/export_integrations", element: <ExportIntegrations />}, 
                    { path: "/email_configuration", element: <EmailConfiguration />}, 
                    { path: "/notification_configuration", element: <IntegrationNotificationConfiguration />}, 
                    { path: "/modules", element: <ModulesConfiguration />}, 
                    { path: "/markets", element: <MarketList />}, 
                    { path: "/market/:marketKey", element: <MarketEdit />}, 
                    { path: "/tax/:taxKey", element: <TaxEdit />}, 
                    { path: "/theme", element: <ThemeConfiguration />}, 
                    { path: "/stock_removal_reason_code/:codeKey", element: <StockRemovalReasonCodeEdit />}, 
                    { path: "/configurable_front_page", element: <ConfigurableFrontPage />}, 
                    { path: "/rules", element: <RuleList />},
                    { path: "/rule/discount/:ruleKey", element: <RuleEdit type="discount" />},
                    { path: "/rule/coupon/:ruleKey", element: <RuleEdit type="coupon" />},
                    { path: "/rule/offer/:ruleKey", element: <RuleEdit type="offer" />},
                    { path: "/product_organization", element: <ProductOrganization />},
                    { path: "/facets", element: <FacetList />},
                    { path: "/facet/:facetKey", element: <FacetEdit />},
                    { path: "/sales/:saleKey", element: <SaleView />},
                    { path: "/sales", element: <SalesList />},
                    { path: "/shops", element: <ShopListWrapper />},
                    { path: "/products", element: <ProductList />},
                    { path: "/users", element: <UserManagement />},
                    { path: "/tags", element: <TagList />},
                    { path: "/tag/:tagKey", element: <TagEdit />},
                    { path: "/product_rules", element: <ProductRuleList />},
                    { path: "/product_rule/:ruleKey", element: <ProductRuleEdit />},
                    { path: "/customer_attributes", element: <AttributeList mode="customer" />},
                    { path: "/attributes", element: <AttributeList mode="product" />},
                    { path: "/attribute_groups", element: <AttributeGroupList />},
                    { path: "/customer_attribute/:attributeKey", element: <AttributeEdit mode="customer" />},
                    { path: "/attribute/:attributeKey", element: <AttributeEdit mode="product" />},
                    { path: "/attribute_group/:attributeGroupKey", element: <AttributeGroupEdit />},
                    { path: "/expenses", element: <ExpenseList />},
                    { path: "/expense/:expenseKey", element: <ExpenseEdit />},
                    { path: "/giftcards", element: <GiftcardList />},
                    { path: "/groups", element: <GroupList />},
                    { path: "/group/:groupKey", element: <GroupEdit />},
                    { path: "/product_recommendations", element: <RecommendationsMaintainance />},
                    { path: "/recommendation_category/:categoryKey", element: <RecommendationCategoryEdit />},
                    { path: "/products/:productKey/new", element: <ProductEdit isNew={true} />},
                    { path: "/products/:productKey", element: <ProductEdit />},
                    { path: "/cashier_roles", element: <CashierRoles />},
                    { path: "/rest_integration", element: <RestIntegration />},
                    { path: "/stock_count_requests", element: <StockCountRequests />},
                    { path: "/stock_count_reports", element: <StockCountReports />},
                    { path: "/custom_reports", element: <CustomReports />}
                ]
            },
            { path: "/no_access", element: <AccessDenied />},
            { path: "/roles", element: <RoleSelectionWrapper/>},
            { path: "/*", element: <h3>Page not found</h3>},
        ]
    }
])

function LandingPageWrapper() {
    const appContext = useAppContext()
    if (appContext.role && appContext.role.isShopOwner()) {
        return <Navigate to={"/shop/" + appContext.role.shop_id}></Navigate>
    } else {
        return <Navigate to="/overview" />
    }
}

function StockListWrapper() {
    const [, stockLocationId, ] = useOutletContext<[any, string, any]>()
    return (
        <StockList stockLocation={stockLocationId} />
    )
}

function ShopListWrapper() {
    const [, , selectStockLocation, deselectStockLocation] = useOutletContext<[any, any, SelectStockLocationCallback, DeselectStockLocationCallback]>()
    return <ShopList selectShop={selectStockLocation} deselectShop={deselectStockLocation} />
}

function RoleSelectionWrapper() {
    const appContext = useAppContext()
    const resolver = appContext.resolver
    const uid = appContext.uid
    if (!resolver.authenticated) {
        return <Navigate to="/login" replace />
    } else if (!_.isNil(resolver.role)) {
        if (resolver.role.isShopOwner() && !_.isNil(resolver.role.shop_id)) {
            return <Navigate to={`/shop/${resolver.role.shop_id}`} replace />

        } else {
            return <Navigate to="/overview" replace />
        }
    } else {
        return <RoleSelection uid={uid} selectRole={appContext.selectRole} />
    }
}

function LoginWrapper() {
    const appContext = useAppContext()
    const resolver = appContext.resolver
    if (resolver.authenticated) {
        return <Navigate to="/roles" replace />
    } else {
        return <Login />
    }
}

export function App() {
    return <RouterProvider router={router}></RouterProvider>
}
