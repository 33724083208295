import * as React from "react";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faBoxes, faCartArrowDown, faCashRegister, faChartBar, faChevronDown, faChevronLeft, faChevronRight, faCircleExclamation, faClipboardCheck, faCog, faDollarSign, faFile, faFileImport, faGift, faGlobeEurope, faHome, faListUl, faPalette, faStore, faTag, faThLarge, faUserFriends, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Role } from "./config/role";
import { Resolver } from "./routes";
import { Dropdown, Nav } from "react-bootstrap";
import { currentDatabaseRef, firebaseAuth, firestore, defaultRef, selectRTDBInstance } from "./config/constants";
import { currentUser, logout } from "./helpers/auth";
import { Globals } from "./helpers/globals";
import { Button, Navbar, NavItem, NavDropdown, Col, Grid, Row, Modal, Alert } from "./components/wrappers";
import { IntlProvider } from "react-intl";
import * as _ from "lodash";
import { Check } from "react-bootstrap-icons";
const version = require("./version.json");
import { detect } from "detect-browser";
import { onAuthStateChanged } from "firebase/auth";
import { doc, DocumentSnapshot, getDoc } from "firebase/firestore"
import { child, DatabaseReference, DataSnapshot, get, off, onValue, Unsubscribe } from "firebase/database";

interface AppState {
    role?: Role
    authenticated: boolean
    loading: boolean
    account_name: string | null
    shop_id: string | null
    stock_location_id: string | null
    shop_name: string | null
    uid: string | null
    expense_module_enabled: boolean
    stock_module_enabled: boolean
    advanced_ui_enabled: boolean
    versionExpired: boolean
    orders_module_enabled: boolean
    configurable_front_page_module_enabled: boolean
    no_access?: boolean
    selectedDropDownId?: string
    shops: any[]
    stockLocations: any[]
    giftcards_enabled: boolean
    giftcard_config_exists: boolean
    trial_limit?: number
    trial_current_value?: number
    customer_lookup_enabled: boolean
}

export interface DropDownModel {
    title: string
    id: string
    icon?: IconDefinition
    items: (MenuItemModel | null)[]
}

export interface MenuItemModel {
    title: string
    icon?: IconDefinition
    path: string
    stockLocationId?: string
    isShop?: boolean
}

export function isDropDown(item: DropDownModel | MenuItemModel): item is DropDownModel {
    return (item as DropDownModel).items !== undefined
}

export function isBrowserSupported(): boolean {
    switch (browser && browser.name) {
        case "edge-chromium":
        case "chrome":
        case "ios":
        case "safari":
            return true

        default:
            return false
    }
}

const browser = detect()

interface KxMenuItemProps {
    // icon: IconDefinition
    // title: string
    item: MenuItemModel | DropDownModel
    isSelected?: boolean
}

export function KxMenuItem(props: KxMenuItemProps) {
    const dropDown = isDropDown(props.item)
    if (props.item.icon) {
        return <div className="kxmenuitem"><span><span><FontAwesomeIcon icon={props.item.icon} /></span></span>{props.item.title}{dropDown && <div style={{ float: "right" }}><FontAwesomeIcon icon={props.isSelected ? faChevronDown : faChevronRight} /></div>}</div>
    } else {
        return <div className="kxmenuitem">{props.item.title}</div>
    }
}

export function KxSubMenuItem(props: KxMenuItemProps) {
    if (props.item.icon) {
        return <div style={{ display: "inline-block" }}><span style={{ width: "30px", display: "inline-block" }}>&nbsp;<FontAwesomeIcon icon={props.item.icon} /></span>{props.item.title}</div>
    } else {
        return <div style={{ display: "inline-block" }}><span style={{ width: "30px", display: "inline-block" }}>&nbsp;</span>{props.item.title}</div>
    }
}

export function useAppContext() {
    return useOutletContext<AppContext>()
}

export type SelectStockLocationCallback = (stock_location_id: string, account_id: string, is_shop: boolean) => Promise<void>
export type DeselectStockLocationCallback = (stock_location_id: string) => void

export interface AppContext {
    role?: Role
    resolver: Resolver,
    stockLocationId: string
    resolveRole: (user_id: string) => Promise<void>
    selectStockLocation: SelectStockLocationCallback
    deselectStockLocation: DeselectStockLocationCallback
    uid: string
    selectRole: (uid: string, role: Role | undefined, selectedShop?: string | undefined, selectedStockLocation?: string | undefined) => Promise<void>
}

// This is the root of the App, which wraps UI such as the navbar and sidebar, and also contains state for the app
// The state is passed along through the Outlet context for the Routes.
export class AppLayout extends React.Component<any, AppState> implements Resolver {
    get role(): Role | undefined {
        return this.state.role;
    }
    get authenticated(): boolean {
        return this.state.authenticated;
    }

    constructor(props: any) {
        super(props);
        this.state = {
            authenticated: false,
            loading: true,
            // account_id: null,
            account_name: null,
            shop_id: null,
            stock_location_id: null,
            shop_name: null,
            uid: null,
            role: undefined,
            stock_module_enabled: false,
            expense_module_enabled: false,
            versionExpired: false,
            advanced_ui_enabled: false,
            orders_module_enabled: false,
            configurable_front_page_module_enabled: false,
            shops: [],
            stockLocations: [],
            giftcard_config_exists: false,
            giftcards_enabled: false,
            customer_lookup_enabled: false
        };
    }

    unsubscribeAuthStateListener: Unsubscribe | null = null;
    listeningToModulesRef: DatabaseReference | null = null;
    listeningToGiftcardConfigRef: DatabaseReference | null = null;
    listeningToLicensingRef: DatabaseReference | null = null;

    async componentDidMount() {
        window.addEventListener("dragover", function (e) {
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e.preventDefault();
        }, false);


        this.unsubscribeAuthStateListener = onAuthStateChanged(firebaseAuth(), async (user) => {
            if (user) {
                await this.resolveRole(user.uid);
                this.setState({
                    authenticated: true,
                    loading: false,
                    uid: user.uid
                });
            } else {
                // localStorage.removeItem("account")
                localStorage.removeItem("shop");
                localStorage.removeItem("role");
                localStorage.removeItem("advanced_ui_enabled");

                if (this.listeningToModulesRef) {
                    off(this.listeningToModulesRef)
                    this.listeningToModulesRef = null
                }
                if (this.listeningToLicensingRef) {
                    off(this.listeningToLicensingRef)
                    this.listeningToLicensingRef = null
                }
                if (this.listeningToGiftcardConfigRef) {
                    off(this.listeningToGiftcardConfigRef)
                    this.listeningToGiftcardConfigRef = null
                }

                Globals.shared.setAccount(null)
                this.setState({
                    authenticated: false,
                    loading: false,
                    account_name: null,
                    shop_id: null,
                    shop_name: null,
                    uid: null,
                    role: undefined,
                    stock_module_enabled: false,
                    advanced_ui_enabled: false,
                    expense_module_enabled: false,
                    orders_module_enabled: false,
                    configurable_front_page_module_enabled: false,
                    trial_current_value: undefined,
                    trial_limit: undefined
                })
            }
        })

        const exactExpiredPath = `v1/versions/Portal/${version.major}_${version.minor}_${version.patch}/expired`

        onValue(child(defaultRef, exactExpiredPath), this.handleExpiredVersionValueChange.bind(this))
        const majorExpiredPath = `v1/versions/Portal/${version.major}/expired`
        onValue(child(defaultRef, majorExpiredPath), this.handleExpiredVersionValueChange.bind(this))
    }

    componentWillUnmount() {
        const expiredPath = `v1/versions/Portal/${version.major}_${version.minor}_${version.patch}/expired`
        off(child(defaultRef, expiredPath))
        const majorExpiredPath = `v1/versions/Portal/${version.major}/expired`
        off(child(defaultRef, majorExpiredPath))

        if (this.unsubscribeAuthStateListener) {
            this.unsubscribeAuthStateListener()
        }
    }

    handleExpiredVersionValueChange(snapshot: DataSnapshot | null) {
        if (!snapshot) {
            return
        }
        if (!snapshot.exists()) {
            return
        }
        if (snapshot.val() === true) {
            this.setState({ versionExpired: true })
        } else {
            this.setState({ versionExpired: false })
        }
    }

    modulesRef(accountId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/modules`)
    }

    licensingRef(accountId: string) {
        return child(currentDatabaseRef(), `v1/licensing/account_usage/${accountId}/trial`)
    }

    giftcardConfigRef(accountId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/giftcard_service_config`)
    }

    shopsRef(accountId: string) {
        const shopsRef = child(currentDatabaseRef(), `v1/accounts/${accountId}/shop_index`)
        return shopsRef;
    }

    stockLocationsRef(accountId: string) {
        const shopsRef = child(currentDatabaseRef(), `v1/accounts/${accountId}/stock_location_index`)
        return shopsRef;
    }

    observeShopsAndStockLocations(accountId: string) {
        onValue(this.shopsRef(accountId), (snapshot) => {
            if (!snapshot || !snapshot.exists()) { return; }
            const shopsDict = snapshot.val();
            const keys = Object.keys(shopsDict);
            const shops = keys
                .map(v => {
                    const shop = shopsDict[v];
                    shop.key = v;
                    return shop;
                })
                .filter(v => { return v.deactivated !== true; })
                .sort((lhs: any, rhs: any) => {
                    const lhsName: string = lhs.name || "";
                    const rhsName: string = rhs.name || "";
                    return lhsName.localeCompare(rhsName);
                });

            this.setState({ shops: shops });
        });

        onValue(this.stockLocationsRef(accountId), (snapshot) => {
            if (!snapshot || !snapshot.exists()) { return; }
            const locationsDict = snapshot.val();
            const keys = Object.keys(locationsDict);
            const locations = keys
                .map(v => {
                    const location = locationsDict[v];
                    location.key = v;
                    return location;
                })
                .filter(v => { return v.deactivated !== true; })
                .sort((lhs: any, rhs: any) => {
                    const lhsName: string = lhs.name || "";
                    const rhsName: string = rhs.name || "";
                    return lhsName.localeCompare(rhsName);
                });

            this.setState({ stockLocations: locations });
        });
    }

    observeGiftcardConfigPresent(accountId: string) {
        if (this.listeningToGiftcardConfigRef) {
            return;
        }
        this.listeningToGiftcardConfigRef = this.giftcardConfigRef(accountId);

        if (!_.isNil(this.listeningToGiftcardConfigRef)) {
            onValue(this.listeningToGiftcardConfigRef, (snapshot) => {
                if (snapshot && snapshot.exists()) {
                    this.setState({
                        giftcard_config_exists: true
                    })
                } else {
                    this.setState({
                        giftcard_config_exists: false
                    })
                }
            })
        }
    }

    observeModulesEnabled(accountId: string) {
        if (this.listeningToModulesRef) {
            return;
        }
        this.listeningToModulesRef = this.modulesRef(accountId);

        onValue(this.listeningToModulesRef, (snapshot) => {
            if (snapshot && snapshot.exists()) {
                const val = snapshot.val();
                this.setState({
                    stock_module_enabled: val.stock?.enabled ?? false,
                    expense_module_enabled: val.expenses?.enabled ?? false,
                    orders_module_enabled: val.orders?.enabled ?? false,
                    configurable_front_page_module_enabled: val.configurable_front_page?.enabled ?? false,
                    giftcards_enabled: val.giftcard?.enabled ?? false,
                    customer_lookup_enabled: val.customer_lookup?.enabled ?? false
                });
            } else {
                this.setState({
                    stock_module_enabled: false,
                    expense_module_enabled: false,
                    orders_module_enabled: false,
                    configurable_front_page_module_enabled: false,
                    giftcards_enabled: false,
                    customer_lookup_enabled: false
                });
            }
        });
    }

    async observeLicensingDetails(accountId: string) {
        const snap = await getDoc(doc(firestore, `licensing/${accountId}`))
        const document = snap.data() ?? {};
        if (document.state === "trial") {
            this.setState({ trial_limit: document.trial_terms?.number_of_checkouts ?? 50 });
            if (this.listeningToLicensingRef) {
                return;
            }
            this.listeningToLicensingRef = this.licensingRef(accountId);
            onValue(this.listeningToLicensingRef, (snapshot) => {
                const currentCheckouts = (snapshot.val() ?? {}).checkouts;
                this.setState({ trial_current_value: currentCheckouts });
            });
        }
    }

    // Does not change any state - only determines the role of the current user.
    // The determination is done as follows:
    // If only one account or shop access is present, return the corresponding role.
    // If several are present, try loading previously selected role from browser storage.
    // Otherwise return 'undefined' - meaning that the role selection UI must be presented
    // in order to resolve a role.
    async determineRole(user_id: string): Promise<Role | undefined> {
        const profileRef = doc(firestore, `profiles/${user_id}`);

        const snapshot = await getDoc(profileRef)
        if (!snapshot.exists) {
            return;
        }
        const profile = snapshot.data() ?? {};
        const accounts = Object.keys(profile.accounts ?? []);
        const shops: any[] = [];
        for (const shopAccount in profile.shops) {
            for (const shop in profile.shops[shopAccount]) {
                shops.push({ account_id: shopAccount, shop_id: shop });
            }
        }
        if (accounts.length + shops.length === 1) {
            const accountSnap: DocumentSnapshot | undefined = await getDoc(doc(firestore, `accounts/${accounts[0]}`)).catch(() => { return undefined; });
            const accountData = accountSnap?.data() ?? {};
            if (accounts.length === 1 && accountData.name !== undefined) {
                const account = accounts[0];
                return new Role(account, undefined, accountData.instance_id, accountData.region);
            } else if (shops.length === 1 && accountData.name !== undefined) {
                const info = shops[0];
                return new Role(info.account_id, info.shop_id, accountData.instance_id, accountData.region);
            }
        }

        let roleFromStorageString: string = localStorage.getItem("role") || "{}";
        if (typeof roleFromStorageString !== "string") {
            roleFromStorageString = "{}";
        }
        const roleFromStorage = JSON.parse(roleFromStorageString);
        const roleAccountId: string | undefined = roleFromStorage.account_id;
        const roleShopId: string | undefined = roleFromStorage.shop_id;

        if (roleAccountId) {
            return new Role(roleAccountId, roleShopId, roleFromStorage.rtdb_instance, roleFromStorage.region);
        }
        return undefined;
    }

    resolveRole = async (user_id: string) => {
        const role = await this.determineRole(user_id);
        const selectedShopId = localStorage.getItem("shop") || undefined;
        const selectedStockLocationId = localStorage.getItem("stock_location") || undefined;

        await this.selectRole(user_id, role, selectedShopId, selectedStockLocationId);
    };

    selectRole = async (uid: string, role: Role | undefined, selectedShop: string | undefined = undefined, selectedStockLocation: string | undefined = undefined) => {
        if (role) {
            const permissionsRef = doc(firestore, `accounts/${role.account_id}/permissions/${uid}`);
            const permissions = (await getDoc(permissionsRef)).data() ?? {};
            if (permissions.disallow_backoffice_access) {
                this.setState({ no_access: true });
                return;
            }
        }

        let stockLocationId: string | null = null;
        if (role) {
            selectRTDBInstance(role.rtdb_instance, role.region);
            Globals.shared.setAccount(role.account_id);
            stockLocationId = role.shop_id || null;
        }

        let isShop = true;

        // It's a shop if the id comes from the role.
        // Otherwise it's a shop if 'selectedShop' is non-null
        if (stockLocationId === null) {
            stockLocationId = selectedShop ?? selectedStockLocation ?? null;
            if (_.isNil(selectedShop)) {
                isShop = false;
            }
        }

        this.setState({ role: role, shop_id: isShop ? stockLocationId : null, stock_location_id: stockLocationId });

        if (!role) {
            localStorage.removeItem("role");
            return;
        }
        localStorage.setItem("role", JSON.stringify(role.json()));
        if (role.account_id) {
            await this.didSelectAccount(role.account_id);
        }
        if (stockLocationId) {
            await this.didSelectStockLocation(stockLocationId, role.account_id, isShop);
        }
        if (role.isAccountOwner()) {
            const advancedUI = localStorage.getItem("advanced_ui_enabled") === "true";
            this.setState({ advanced_ui_enabled: advancedUI });
        }
    };

    didSelectAccount = async (account_id: string) => {
        this.observeModulesEnabled(account_id);
        this.observeGiftcardConfigPresent(account_id);
        this.observeShopsAndStockLocations(account_id);
        this.observeLicensingDetails(account_id);

        const snapshot = await get(child(currentDatabaseRef(), `v1/account_index/${account_id}/name`))
        this.setState({
            account_name: snapshot.val()
        });
    };

    deselectStockLocation = (stock_location_id: string) => {
        if (this.state.stock_location_id === stock_location_id) {
            localStorage.removeItem("shop");
            localStorage.removeItem("stock_location");
            this.setState({
                shop_id: null,
                shop_name: null,
                stock_location_id: null
            });
        }
    };

    selectStockLocation = async (stock_location_id: string, account_id: string, is_shop: boolean) => {
        this.setState({ shop_id: is_shop ? stock_location_id : null, stock_location_id: stock_location_id });
        await this.didSelectStockLocation(stock_location_id, account_id, is_shop);
    };

    async didSelectStockLocation(stock_location_id: string, account_id: string, is_shop: boolean) {
        if (is_shop) {
            localStorage.setItem("shop", stock_location_id);
        } else {
            localStorage.removeItem("shop");
        }
        localStorage.setItem("stock_location", stock_location_id);

        if (is_shop) {
            const snapshot = await get(child(currentDatabaseRef(), `v1/accounts/${account_id}/shop_index/${stock_location_id}/name`))
            this.setState({
                shop_name: snapshot.val()
            });
        } else {
            this.setState({
                shop_name: "Online"
            });
        }
    }

    isAccountOwner() {
        if (this.state.role) {
            return this.state.role.isAccountOwner();
        }
        return false;
    }

    isShopOwner() {
        if (this.state.role) {
            return this.state.role.isShopOwner();
        }
        return false;
    }

    get shopId(): string {
        return this.state.shop_id || "_";
    }

    get stockLocationId(): string {
        return this.state.stock_location_id || "_";
    }

    get showGiftcards(): boolean {
        return this.state.giftcards_enabled && this.state.giftcard_config_exists;
    }

    // Navigation to the overview is the same as clearing out the
    // stock location id and shop id, both from state and from storage.
    // Also expand shops menu item again
    navigateToOverview() {
        if (this.state.stock_location_id) {
            this.deselectStockLocation(this.state.stock_location_id);
        }
        this.setState({ selectedDropDownId: "shops" });
    }

    accountMenuItems(): (MenuItemModel | DropDownModel)[] {
        const shops: MenuItemModel[] = this.state.shops.map(shop => {
            return {
                title: shop.name ?? "(Unnamed shop)",
                icon: faStore,
                path: "/shop/" + shop.key,
                stockLocationId: shop.key,
                isShop: true
            };
        });

        const stockLocations: MenuItemModel[] = (this.state.stock_module_enabled ?
            this.state.stockLocations.map(location => {
                return {
                    title: location.name ?? location.key,
                    icon: faBoxes,
                    path: "/stock_location/" + location.key + "/edit",
                    stockLocationId: location.key,
                    isShop: false
                };
            }) : []);

        for (const shop of this.state.shops) {
            _.remove(stockLocations, location => { return location.stockLocationId === shop.key; });
        }
        const allLocations = [{
            title: "Add / remove shops",
            icon: faListUl,
            path: "/shops/"
        } as MenuItemModel].concat(shops).concat(stockLocations);

        const items: (MenuItemModel | DropDownModel | null)[] = [
            // {
            //     title: "Accounting", items: [
            //         { title: "Sales", path: "/sales" },
            //     ]
            // },
            // {
            //     title: "Inventory", items: [
            //         { title: "Products", path: "/products" },
            //         { title: "Product Import", path: "/product_import" },
            //         { title: "Product Recommendations", path: "/product_recommendations" },
            //         { title: "Groups", path: "/groups" },
            //         { title: "Tags", path: "/tags" },
            //         { title: "Discount Rules", path: "/rules" }
            //     ]
            // },
            // {
            //     title: "Setup", items: [
            //         { title: "Theme", path: "/theme" },
            //         { title: "Users", path: "/users" },
            //         { title: "Modules", path: "/modules" },
            //         { title: "Configuration", path: "/configuration" },
            //         { title: "Markets", path: "/markets" },
            //     ]
            // },
            // { title: "Shops", path: "/shops" },
            { title: `Home`, icon: faHome, path: "/overview" },
            {
                title: "Inventory", icon: faTag, id: "inventory", items: [
                    { title: "Products", path: "/products" },
                    { title: "Product Import", path: "/product_import" },
                    { title: "Recommendations", path: "/product_recommendations" },
                    { title: "Discounts", path: "/rules" },
                    { title: "Product Rules", path: "/product_rules" },
                    this.state.expense_module_enabled ? { title: "Expenses", path: "/expenses" } : null,
                    { title: "Product Groups", path: "/groups" },
                    { title: "Tags", path: "/tags" },
                    { title: "Facets", path: "/facets" },
                    { title: "Product Attributes", path: "/attributes" },
                    { title: "Attribute Groups", path: "/attribute_groups" },
                    { title: "Product Organization", path: "/product_organization" },
                    { title: "Customer Attributes", path: "/customer_attributes" },
                    { title: "Cashier roles", path: "/cashier_roles" },
                    this.state.configurable_front_page_module_enabled ? { title: "Configurable front page", path: "/configurable_front_page" } : null,
                ]
            },
            { title: "Sales", icon: faDollarSign, path: "/sales" },
            // TODO: Find better icon
            this.state.customer_lookup_enabled ? { title: "Customers", icon: faUserFriends, path: "/customers" } : null,
            this.showGiftcards ? { title: "Gift cards", icon: faGift, path: "/giftcards" } : null,
            {
                title: "Shops", icon: faStore, id: "shops", items: allLocations
            },
            { title: "Users", icon: faUsers, path: "/users" },
            this.state.stock_module_enabled ? {
                title: "Stock counts", icon: faClipboardCheck, id: "stock_counts", items: [
                    { title: "Stock count requests", icon: faCircleExclamation, path: "/stock_count_requests" },
                    { title: "Reports", icon: faFile, path: "/stock_count_reports" }
                ],
            } : null,
            { title: "Custom reports", icon: faFile, id: "custom_reports", path: "/custom_reports" },
            {
                title: "Configuration", icon: faCog, id: "configuration", items: [
                    { title: "General", icon: faCog, path: "/configuration" },
                    { title: "Modules", icon: faThLarge, path: "/modules" },
                    { title: "Markets", icon: faGlobeEurope, path: "/markets" },
                    { title: "Theme", icon: faPalette, path: "/theme" },
                    this.showGiftcards ? { title: "Gift cards", icon: faGift, path: "/configuration/gift_card_types" } : null
                ]
            }
        ];
        function notEmpty<TValue>(value: TValue | null): value is TValue {
            return value !== null;
        }

        const filtered: (MenuItemModel | DropDownModel)[] = items.filter(notEmpty);
        return filtered;
    }

    advancedMenuItems(): (MenuItemModel | DropDownModel)[] {
        if (!this.state.advanced_ui_enabled) { return []; }
        const items: (MenuItemModel | DropDownModel)[] = [
            { title: "Import Integrations", path: `/import_integrations` },
            { title: "Export Integrations", path: `/export_integrations` },
            { title: "REST Integration", path: "/rest_integration" },
            { title: "Runtime Integrations", path: `/runtime_integrations` },
            { title: "Email Configuration", path: "/email_configuration" },
            { title: "Notification Configuration", path: "/notification_configuration" }
        ];
        return items;
    }

    shopMenuItems(): (MenuItemModel | DropDownModel)[] {
        if (!this.state.shop_id) { return []; }
        // const inventory: MenuItemModel[] = [
        //     { title: "Shop Specific Products", path: `/shop_products` },
        // ]
        // if (this.isShopOwner()) {
        //     inventory.push({ title: "Account Products (read only)", path: "/products" })
        // }
        // const items: (MenuItemModel | DropDownModel)[] = [
        //     {
        //         title: "Accounting", items: [
        //             { title: "Sales", path: `/shop/${this.state.shop_id}/sales` },
        //             { title: "Register Close Statements", path: `/shop/${this.state.shop_id}/register_close_statements` },
        //             { title: "Reports", path: `/shop/${this.state.shop_id}/reports` },
        //         ]
        //     },
        //     { title: "Inventory", items: inventory },
        //     {
        //         title: "Setup", items: [
        //             { title: "Shop Details", path: `/shop_edit/${this.state.shop_id}` },
        //             { title: "Shop Configuration", path: `/shop_configuration/${this.state.shop_id}` },
        //             { title: "Cashiers", path: `/shop/${this.state.shop_id}/cashiers` },
        //         ]
        //     }
        // ]
        // if (this.state.stock_module_enabled === true) {
        //     items.push({
        //         title: "Stock", items: [
        //             { title: "Current Stock", path: `/shop/${this.state.shop_id}/stock` },
        //             { title: "Stock Counts", path: `/shop/${this.state.shop_id}/stock_counts` },
        //             { title: "Stock Import", path: `/shop/${this.state.shop_id}/stock_import` }
        //         ]
        //     })
        // }
        const items: (MenuItemModel | DropDownModel)[] = [
            {
                title: "Configuration", icon: faCog, id: "shop_configuration", items: [
                    { title: "Edit Shop", icon: faStore, path: `/shop/${this.state.shop_id}/edit` },
                    { title: "Shop Configuration", icon: faCog, path: `/shop/${this.state.shop_id}/configuration` },
                    { title: "Budgets", icon: faChartBar, path: `/shop/${this.state.shop_id}/budget` },
                    { title: "Cashiers", icon: faUsers, path: `/shop/${this.state.shop_id}/cashiers` }
                ]
            }
        ];

        if (this.isShopOwner()) {
            items.push(
                // {title: "Inventory", icon: faTag, id: "shop_inventory", items: [
                { title: "Account Products", icon: faTag, path: `/shop/${this.state.shop_id}/products` }
                // ]}
            );
        }
        items.push(
            { title: "Sales", icon: faDollarSign, path: `/shop/${this.state.shop_id}/sales` },
            { title: "Register Close Statements", icon: faCashRegister, path: `/shop/${this.state.shop_id}/register_close_statements` },
            { title: "Reports", icon: faFile, path: `/shop/${this.state.shop_id}/reports` }
        );

        if (this.isAccountOwner() && (this.state.orders_module_enabled === true)) {
            items.push({ title: "Order handling Demo", icon: faCartArrowDown, path: `/shop/${this.state.shop_id}/reserve_and_collect_demo` });
        }

        return items;
    }

    stockLocationMenuItems(): (MenuItemModel | DropDownModel)[] {
        if (!this.state.stock_location_id) { return []; }

        if (this.state.stock_module_enabled !== true) { return []; }

        const items: (MenuItemModel | DropDownModel)[] = [];

        if (_.isNil(this.state.shop_id)) {
            items.push({ title: "Edit Stock Location", path: `/stock_location/${this.state.stock_location_id}/edit` });
        }

        items.push(
            { title: "Current Stock", icon: faBoxes, path: `/stock_location/${this.state.stock_location_id}/stock` },
            { title: "Stock Counts", icon: faClipboardCheck, path: `/stock_location/${this.state.stock_location_id}/stock_counts` },
            { title: "Stock Import", icon: faFileImport, path: `/stock_location/${this.state.stock_location_id}/stock_import` }
        );

        return items;
    }

    userMenuItems() {
        return [
            (
                <Dropdown.Item key="account" disabled={true}>
                    {this.state.account_name}
                </Dropdown.Item>
            ),
            <Dropdown.Divider key="account-divider" />,
            this.isAccountOwner() ?
                [
                    (
                        <Dropdown.Item
                            key="first"
                            onClick={async () => {
                                const newState = !this.state.advanced_ui_enabled;
                                this.setState({ advanced_ui_enabled: newState });
                                localStorage.setItem("advanced_ui_enabled", `${newState}`);
                            }}
                        >
                            {this.state.advanced_ui_enabled ? <Check /> : ""} Advanced Options
                        </Dropdown.Item>
                    ),
                    <Dropdown.Divider key="second" />,
                ]
                : null,
            (
                <Dropdown.Item key="logout" onClick={async () => {
                    await logout();
                }}>
                    Log out
                </Dropdown.Item>
            ),
            <Dropdown.Divider key="bottom-divider" />,
            (
                <Dropdown.Item key="version" disabled={true}>
                    v{version.major}.{version.minor}.{version.patch}
                </Dropdown.Item>
            )
        ];
    }

    renderAccountMenuItems() {
        return this.renderMenuItems(this.accountMenuItems());
    }

    renderMenuItems(models: (MenuItemModel | DropDownModel)[], isSubItem: boolean = false) {
        const items: JSX.Element[] = [];
        for (const index in models) {
            const model = models[index];
            if (isDropDown(model)) {
                const title = <KxMenuItem item={model} isSelected={this.state.selectedDropDownId === model.id} />;
                if (this.state.selectedDropDownId === model.id) {
                    items.push(<Nav.Item key={model.id} onClick={() => { this.setState({ selectedDropDownId: undefined }); }}><Nav.Link>{title}</Nav.Link></Nav.Item>);
                    for (const subIndex in model.items) {
                        const subItem = model.items[subIndex];
                        if (subItem === null) { continue; }
                        const subKey = `${model.id}_${parseInt(index) * 100 + parseInt(subIndex)}`;
                        const subItemTitle = <KxSubMenuItem item={subItem} />;
                        const navItem = <Nav.Item className="subitem" key={subKey} onClick={() => { if (subItem.stockLocationId) { this.selectStockLocation(subItem.stockLocationId, this.role?.account_id ?? "", subItem.isShop ?? false); } }}><Nav.Link eventKey={subItem.path} as={Link} to={subItem.path}>{subItemTitle}</Nav.Link></Nav.Item>;
                        items.push(navItem);
                    }
                } else {
                    const item = <Nav.Item key={model.id} className={isSubItem ? "subitem" : ""} onClick={() => { this.setState({ selectedDropDownId: model.id }); }}><Nav.Link>{title}</Nav.Link></Nav.Item>;
                    items.push(item);
                }

            } else {
                const title = <KxMenuItem item={model} />;
                const navItem = <Nav.Item onClick={() => { this.setState({ selectedDropDownId: undefined }); }} key={index} className={isSubItem ? "subitem" : ""}><Nav.Link eventKey={model.path} as={Link} to={model.path}>{title}</Nav.Link></Nav.Item>;
                items.push(navItem); // <LinkContainer onClick={() => { this.setState({ selectedDropDownId: undefined }) }} key={index} to={model.path}>{navItem}</LinkContainer>)
            }
        }

        return items;
    }

    renderNavBar() {
        return (
            <Navbar id="bootstrap-override" fixed={"top"} style={{ paddingLeft: "10px", paddingRight: "30px" }}>
                {/* <Navbar.Header> */}
                <Navbar.Brand>
                    <Link to="/"><img src="/backoffice/assets/ka-ching-logo.png" height="55" alt="Ka-ching logo" /></Link>
                </Navbar.Brand>
                <Navbar.Toggle />
                {/* </Navbar.Header> */}
                <Navbar.Collapse className="justify-content-end">
                    {this.state.authenticated
                        ?
                        [
                            (
                                <Nav.Item key="b"><Nav.Link eventKey={2} href="https://ka-ching.zendesk.com" target="_blank">
                                    Support
                                </Nav.Link></Nav.Item>
                            ),
                            (
                                <NavDropdown
                                    /* noCaret={true} */
                                    title={currentUser()?.email?.substring(0, 1).toUpperCase()}
                                    id="a"
                                    key="a"
                                    style={{
                                        color: "rgb(57,52,90)",
                                        textAlign: "center",
                                        fontWeight: 500,
                                        fontSize: "16px"
                                    }}
                                >
                                    {this.userMenuItems()}
                                </NavDropdown>
                            ),
                            (
                                <NavDropdown key="c" id="b" title={currentUser()?.email}>{this.userMenuItems()}</NavDropdown>
                            )
                        ]
                        :
                        (
                            <Nav.Item key="login"><Nav.Link eventKey={2} href="/">
                                Login
                            </Nav.Link></Nav.Item>
                        )}
                </Navbar.Collapse>
            </Navbar>
        );
    }

    render() {

        const showUpdate = this.state.versionExpired;
        const updateContainerStyle: any = {
            border: "2px solid black",
            borderRadius: "10px",
            background: "white",
            position: "fixed",
            bottom: "5%",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
        };
        const updateStyle: any = {
            textColor: "gray",
            textAlign: "center",
            margin: "10px"
        };

        if (isBrowserSupported() === false) {
            return this.renderUnsupportedBrowser();
        }

        if (this.state.no_access === true) {
            return this.noAccessView()
        }

        return this.state.loading === true ? this.loadingView() : this.mainView(showUpdate, updateContainerStyle, updateStyle)

    }

    private noAccessView() {
        return <IntlProvider defaultLocale="en" locale={navigator.language}>
            <Navbar id="bootstrap-override" fixed={"top"} style={{ paddingLeft: "10px", paddingRight: "30px" }}>
                <Navbar.Brand>
                    <Link to="/"><img src="/backoffice/assets/ka-ching-logo.png" height="55" alt="Ka-ching logo" /></Link>
                </Navbar.Brand>
                <Nav>
                    <NavItem>No access...</NavItem>
                </Nav>
                <Navbar.Collapse className="justify-content-end" /* pullRight={true} */>
                    <Nav.Item key="b"><Nav.Link eventKey={2} href="https://ka-ching.zendesk.com">
                        Support
                    </Nav.Link></Nav.Item>
                </Navbar.Collapse>
            </Navbar>
            <Modal show={true} key="b">
                <Modal.Header>
                    <Modal.Title>No access</Modal.Title>
                </Modal.Header>

                <Modal.Body>The currently signed in user has no access rights to Ka-ching Back Office on the selected account. Please contact your account administrator.</Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => { this.setState({ no_access: undefined }); }}>OK</Button>
                </Modal.Footer>
            </Modal>
        </IntlProvider>;
    }

    private loadingView() {
        return <IntlProvider defaultLocale="en" locale={navigator.language}>
            <Navbar id="bootstrap-override" fixed={"top"} style={{ paddingLeft: "10px", paddingRight: "30px" }}>
                {/* <Navbar.Header> */}
                <Navbar.Brand>
                    <Link to="/"><img src="/backoffice/assets/ka-ching-logo.png" height="55" alt="Ka-ching logo" /></Link>
                </Navbar.Brand>
                {/* </Navbar.Header> */}
                <Nav>
                    <NavItem>Loading...</NavItem>
                </Nav>
                <Navbar.Collapse className="justify-content-end" /* pullRight={true} */>
                    <Nav.Item key="b"><Nav.Link eventKey={2} href="https://ka-ching.zendesk.com">
                        Support
                    </Nav.Link></Nav.Item>
                </Navbar.Collapse>
            </Navbar>
        </IntlProvider>;
    }

    private mainView(showUpdate: boolean, updateContainerStyle: any, updateStyle: any) {
        return <IntlProvider defaultLocale="en" locale={navigator.language}>
            <div>
                {this.renderNavBar()}

                <div>
                    {showUpdate
                        ?
                        (
                            <div style={updateContainerStyle}>
                                <p style={updateStyle}><h4>A new version of Ka-ching is available</h4><button onClick={() => { window.location.reload(); }}>Click here to refresh</button></p>
                            </div>
                        )
                        :
                        null}
                    <div className="colrow">
                        {this.state.role && (
                            <Col xs={12} sm={4} md={2} lg={2} style={{ fontWeight: 500, fontSize: "14px", paddingLeft: "15px", paddingRight: "15px" }}>
                                <div style={{ paddingTop: "75px" }}>
                                    {(_.isNil(this.state.shop_id) && _.isNil(this.state.stock_location_id) && this.isAccountOwner())
                                        &&
                                        (
                                            <div>
                                                {!_.isNil(this.state.trial_current_value) && !_.isNil(this.state.trial_limit) &&
                                                    <Alert variant={(this.state.trial_limit - this.state.trial_current_value) <= 10 ? "danger" : "warning"}>
                                                        <span>Trial license: {this.state.trial_current_value} out of {this.state.trial_limit} sales have been made.</span>
                                                    </Alert>}
                                                <Nav variant="pills" /* stacked={true} */ className="flex-column">
                                                    {this.renderAccountMenuItems()}
                                                </Nav>
                                                <br />
                                            </div>
                                        )}
                                    {(!_.isNil(this.state.shop_id) || !_.isNil(this.state.stock_location_id)) && this.isAccountOwner() && (
                                        <Button variant="link" onClick={() => { this.navigateToOverview(); }}><FontAwesomeIcon icon={faChevronLeft} />&nbsp;Back</Button>
                                    )}
                                    {(!_.isNil(this.state.shop_id) && (this.isAccountOwner() || this.isShopOwner()))
                                        &&
                                        (
                                            <div>
                                                <Nav variant="pills" /*stacked={true}*/ className="flex-column" defaultActiveKey="/overview">
                                                    <NavItem key="navitem" disabled={true}>SHOP</NavItem>
                                                    <NavItem><Nav.Link key="linkcontainer" as={Link} to={"/shop/" + this.state.shop_id}>{this.state.shop_name || ""}</Nav.Link></NavItem>
                                                    {this.renderMenuItems(this.shopMenuItems())}
                                                </Nav>
                                                <br />
                                            </div>
                                        )}
                                    {this.state.stock_location_id && this.state.stock_module_enabled === true && (this.isAccountOwner() || this.isShopOwner())
                                        ?
                                        (
                                            <div>
                                                <Nav variant="pills" className="flex-column">
                                                    <NavItem disabled={true}>STOCK</NavItem>
                                                    {this.renderMenuItems(this.stockLocationMenuItems())}
                                                </Nav>
                                                <br />
                                            </div>
                                        )
                                        : null}
                                    {this.state.advanced_ui_enabled
                                        ?
                                        (
                                            <div>
                                                <Nav variant="pills" /*stacked={true}*/ className="flex-column">
                                                    <NavItem disabled={true}>ADVANCED</NavItem>
                                                    {this.renderMenuItems(this.advancedMenuItems(), true)}
                                                </Nav>
                                                <br />
                                            </div>
                                        )
                                        :
                                        <div />}
                                    {/* Make it possible to scroll a bit further than to the exact bottom of the menu */}
                                    <br /><br /><br />
                                </div>
                            </Col>
                        )}
                        <Col xs={12} sm={this.state.role ? 8 : 12} md={this.state.role ? 9 : 12} lg={this.state.role ? 10 : 12} style={{ backgroundColor: "rgb(245, 245, 245)" }}>
                            <div style={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "75px" }}>
                                <Outlet context={{ role: this.state.role, resolver: this, stockLocationId: this.stockLocationId, resolveRole: this.resolveRole, selectStockLocation: this.selectStockLocation, deselectStockLocation: this.deselectStockLocation, uid: this.state.uid!, selectRole: this.selectRole } satisfies AppContext}></Outlet>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </IntlProvider>;
    }

    renderUnsupportedBrowser() {
        const browserName = browser !== null ? browser.name.charAt(0).toUpperCase() + browser.name.slice(1) : "an unknown browser";

        return (
            <Grid>
                <Row className="text-center"><h1>Unsupported browser</h1></Row>
                <Row className="text-center"><p>You seem to be running <strong>{browserName}</strong> which is currently unsupported.</p></Row>
                <Row className="text-center"><p>The Ka-ching backoffice only supports using <strong>Chrome</strong> or <strong>Safari</strong>.</p></Row>
            </Grid>
        );
    }
}
