import * as React from "react"
import { PageState } from "../PageState"
import { RoleRouterProps, RoleShopProps, withRoleRouterFunc, withShop } from "../../routes"
import * as _ from "lodash"
import { getFunctions, httpsCallable } from "firebase/functions"
import { useEffect, useRef, useState } from "react"
import { Globals } from "../../helpers/globals"
import { Dropdown, Overlay, Button, Card } from "react-bootstrap"
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import dayjs from "dayjs"
import { LabelledControl } from "./ProductEdit"
import { Market } from "../../models/MarketModels"
import { FormattedDate } from "react-intl"
import { createPortal } from "react-dom"

const timePeriod = {
    day: "Day",
    week: "Week",
    month: "Month",
    year: "Year"
}

const reportTypes: _.Dictionary<string> = {
    "shop_summary": "Shop overview",
    "market_summary": "Market overview",
    "cashier_summary": "Cashier overview",
    "market_leaderboard": "Product leaderboard, market",
    "shop_leaderboard": "Product leaderboard, shop",
    // "sales_lineitems": "Sales, line items",
    // "sales_transactions": "Sales, transactions",
}

const sortByTypes: _.Dictionary<string> = {
    "total": "Totals",
    "item_count": "Items sold",
    "margin": "Margin"
}

const kindTypes: _.Dictionary<string> = {
    "products": "Products",
    "sellables": "Variants"
}

const compareWithTypes: _.Dictionary<string> = {
    "none": "Unselected",
    "yesterday": "Previous day",
    "last_week": "Previous week",
    "last_month": "Previous month",
    "last_year": "Previous year"
}

function compareWithTypesForPeriod(period: string) {
    switch (period) {
        case "day":
            return compareWithTypes
        case "week":
            return {
                "none": "Unselected",
                "last_week": "Previous week",
                "last_month": "Previous month",
                "last_year": "Previous year"
            }
        case "month":
            return {
                "none": "Unselected",
                "last_month": "Previous month",
                "last_year": "Previous year"
            }
        case "year":
            return {
                "none": "Unselected",
                "last_year": "Previous year"
            }
        default:
            return compareWithTypes
    }
}

function Reports(props: RoleRouterProps) {
    const target = useRef(null)

    const [showCalendar, setShowCalendar] = useState<boolean>(false)
    const [content, setContent] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [shops, setShops] = useState<_.Dictionary<string>>({})
    const [markets, setMarkets] = useState<Market[]>([])
    const [selectedMarket, setSelectedMarket] = useState<string | undefined>()
    const [selectedReportType, setSelectedReportType] = useState<string>("shop_summary")
    const [selectedShop, setSelectedShop] = useState<string | undefined>("*")
    const [selectedSortByType, setSelectedSortByType] = useState<string>("total")
    const [selectedKind, setSelectedKind] = useState<string>("products")

    const [date, setDate] = useState<Date>(new Date())
    const [dateString, setDateString] = useState<string>(dayjs(new Date()).local().format("YYYY-MM-DD"))
    const [selectedTimeResolution, setSelectedTimeResolution] = useState<string>("day")
    const [selectedCompareWithPeriod, setSelectedCompareWithPeriod] = useState<string | undefined>()

    const [selectedFormat, setSelectedFormat] = useState<"csv" | "pdf" | "html">("html")
    const accountId = props.role.account_id
    const typeId = props.router.params.typeId

    useEffect(() => {
        setIsLoading(true)
        const shopsPromise = Globals.shared.getShops()
        shopsPromise.then(shops => {
            setShops(shops)
            setIsLoading(false)
        })
        const marketsPromise = Globals.shared.getMarkets()
        marketsPromise.then(markets => {
            setMarkets(markets)
            if (markets.length >= 1) {
                setSelectedMarket(markets[0].id)
            }
        })
    }, [props.role.account_id])

    function getReport(format: "csv" | "html" | "pdf") {
        const callable = httpsCallable<any, any>(getFunctions(), "clientApi")
        const args: any = {
            action: "generate-report",
            account: props.role.account_id,
            report: selectedReportType,
            date: dateString,
            time_resolution: selectedTimeResolution,
            format: format,
            csv_locale: "da"
        }

        if (!_.isNil(selectedShop)) {
            args.source_value = selectedShop
        }
        if (selectedShop === "*") {
            args.source_resolution = "market"
            args.source_value = selectedMarket
            args.sub_sources = "*"
        }
        if (selectedReportType === "market_summary") {
            args.source_resolution = "market"
            args.source_value = selectedMarket
            delete args.sub_sources
        }

        if (selectedReportType === "cashier_summary") {
            args.source_resolution = "shop"
            args.source_value = selectedShop
            args.sub_sources = "*"
        }
        if (selectedReportType === "shop_leaderboard") {
            args.source_resolution = "shop"
            args.source_value = selectedShop
            args.sub_sources = "*"
            args.report = "product_leaderboard"
            args.sort_property = selectedSortByType
            args.kind = selectedKind
            args.limit = 100
        }
        if (selectedReportType === "market_leaderboard") {
            args.source_resolution = "market"
            args.source_value = selectedMarket
            args.sub_sources = "*"
            args.report = "product_leaderboard"
            args.sort_property = selectedSortByType
            args.kind = selectedKind
            args.limit = 100
        }

        if (!_.isNil(selectedCompareWithPeriod) && selectedCompareWithPeriod !== "none") {
            args.compare_with = selectedCompareWithPeriod
        }

        const reportPromise = callable(args)
        return reportPromise
    }

    useEffect(() => {
        setContent(undefined)

        const reportPromise = getReport("html")

        reportPromise.then(report => {
            setContent(report.data.content)
        })

    }, [props.role.account_id, selectedShop, dateString, selectedTimeResolution, selectedFormat, selectedCompareWithPeriod, selectedReportType, selectedKind, selectedSortByType])

    function shopName() {
        if (selectedShop === undefined) {
            return "Select a shop"
        } else if (selectedShop === "*") {
            return "All shops"
        } else {
            return shops[selectedShop]
        }
    }

    function marketName() {
        if (selectedMarket === undefined) {
            return "Select a market"
        } else {
            return markets.find(market => { return market.id === selectedMarket })?.name ?? "Select a market"
        }
    }

    function updatePeriod(period: string) {
        setSelectedTimeResolution(period)
        const compareWith = compareWithTypesForPeriod(period)
        if (compareWith[selectedCompareWithPeriod ?? "none"] === undefined) {
            setSelectedCompareWithPeriod("none")
        }
    }

    function showMarketSelection() {
        if (markets.length <= 1) {
            return false
        }
        if (selectedReportType === "shop_leaderboard") { return false }
        return true
    }

    function showShopSelection() {
        if (selectedReportType === "shop_leaderboard") { return true }
        if (selectedReportType === "shop_summary") { return true }
        if (selectedReportType === "cashier_summary") { return true }
        return false
    }
    function showAllShopsOption() {
        if (selectedReportType === "shop_summary") { return true }
        return false
    }
    function showLeaderboardFields() {
        if (selectedReportType === "shop_leaderboard") { return true }
        if (selectedReportType === "market_leaderboard") { return true }
        return false
    }
    function showCompareWith() {
        if (selectedReportType === "market_summary") { return true }
        if (selectedReportType === "shop_summary") { return true }
        if (selectedReportType === "cashier_summary") { return true }
        return false
    }

    return (
        <PageState loading={isLoading} publishing={false} dirty={isDirty} typeName="report" submit_action={async () => { }}>
            <Card className="my-4">
                <Card.Header>Reports</Card.Header>
                <Card.Body>

                    <span style={{ display: "flex", gap: "20px" }}>

                        <LabelledControl label="Report type">

                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-button">
                                    {
                                        reportTypes[selectedReportType]
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {

                                        Object.keys(reportTypes).map((key) => {
                                            const name = reportTypes[key]
                                            return (
                                                <Dropdown.Item active={key === selectedReportType} key={key} onClick={() => {
                                                    setSelectedReportType(key)
                                                    if (key === "shop_leaderboard" && (selectedShop === "*" || selectedShop === undefined)) {
                                                        setSelectedShop(Object.keys(shops).length > 0 ? Object.keys(shops)[0] : undefined)
                                                    }
                                                }}> {name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>

                            </Dropdown>
                        </LabelledControl>

                        {showMarketSelection() &&
                            <LabelledControl label="Market">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic-button">
                                        {
                                            marketName()
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {

                                            markets.map((market) => {
                                                const name = market.name
                                                return (
                                                    <Dropdown.Item active={market.id === selectedMarket} key={market.id} onClick={() => {
                                                        setSelectedMarket(market.id)
                                                    }}> {name}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>

                                </Dropdown>
                            </LabelledControl>
                        }

                        {showShopSelection() &&

                            <LabelledControl label="Shop">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic-button">
                                        {
                                            shopName()
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {showAllShopsOption() &&
                                            <Dropdown.Item active={"*" === selectedShop} key="*" onClick={() => {
                                                setSelectedShop("*")
                                            }}> All shops
                                            </Dropdown.Item>
                                        }

                                        {

                                            Object.keys(shops).map((key) => {
                                                const name = shops[key]
                                                return (
                                                    <Dropdown.Item active={key === selectedShop} key={key} onClick={() => {
                                                        setSelectedShop(key)
                                                    }}> {name}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>

                                </Dropdown>
                            </LabelledControl>
                        }

                        {/* <LabelledControl label="Format">

                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-button">
                                    {
                                        selectedFormat.toUpperCase()
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {

                                        (["csv", "pdf", "html"] as ("csv" | "pdf" | "html")[]).map((key: "csv" | "pdf" | "html") => {
                                            const name = key.toUpperCase()
                                            return (
                                                <Dropdown.Item active={key === selectedFormat} key={key} onClick={() => {
                                                    setSelectedFormat(key)
                                                }}> {name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>

                            </Dropdown>
                        </LabelledControl> */}

                        <LabelledControl label="Period">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-button">
                                    {
                                        timePeriod[selectedTimeResolution]
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {

                                        ["day", "week", "month", "year"].map((key) => {
                                            const name = timePeriod[key]
                                            return (
                                                <Dropdown.Item active={key === selectedTimeResolution} key={key} onClick={() => {
                                                    updatePeriod(key)
                                                }}> {name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>

                            </Dropdown>
                        </LabelledControl>

                        {showCompareWith() && <LabelledControl label="Compare with">

                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-button">
                                    {
                                        compareWithTypes[selectedCompareWithPeriod ?? "none"]
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {

                                        Object.keys(compareWithTypesForPeriod(selectedTimeResolution)).map((key) => {
                                            const name = compareWithTypes[key]
                                            return (
                                                <Dropdown.Item active={key === (selectedCompareWithPeriod ?? "none")} key={key} onClick={() => {
                                                    setSelectedCompareWithPeriod(key)
                                                }}> {name}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>

                            </Dropdown>
                        </LabelledControl>
                        }

                        {showLeaderboardFields() && <>

                            <LabelledControl label="Grouping">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic-button">
                                        {
                                            kindTypes[selectedKind]
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {

                                            Object.keys(kindTypes).map((key) => {
                                                const name = kindTypes[key]
                                                return (
                                                    <Dropdown.Item active={key === selectedKind} key={key} onClick={() => {
                                                        setSelectedKind(key)
                                                    }}> {name}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>

                                </Dropdown>
                            </LabelledControl>

                            <LabelledControl label="Sort by">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic-button">
                                        {
                                            sortByTypes[selectedSortByType]
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {

                                            Object.keys(sortByTypes).map((key) => {
                                                const name = sortByTypes[key]
                                                return (
                                                    <Dropdown.Item active={key === selectedSortByType} key={key} onClick={() => {
                                                        setSelectedSortByType(key)
                                                    }}> {name}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>

                                </Dropdown>
                            </LabelledControl>

                        </>}

                        <LabelledControl label="Date">
                            <span>
                                <Button ref={target} onClick={() => { setShowCalendar(!showCalendar) }} style={{ width: "100%" }}>

                                    {date ? <FormattedDate
                                        value={date}
                                        day="numeric"
                                        month="long"
                                        year="numeric"
                                    /> : <span style={{ color: "#888888" }}>{"Select a date"}&nbsp;</span>}

                                </Button>
                                <Overlay rootClose={true} onHide={() => setShowCalendar(false)} target={target.current} show={showCalendar} placement="bottom-start">
                                    <div style={{
                                        zIndex: 2000,
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                        padding: '2px 2px',
                                        color: 'white',
                                        borderRadius: "0.375rem",
                                        borderWidth: 1,
                                        borderColor: "#dddddd",
                                        borderStyle: "solid"
                                    }}>
                                        <Calendar date={date} onChange={(date) => {
                                            setShowCalendar(false)
                                            setDate(date)
                                            const dateString = dayjs(date).local().format("YYYY-MM-DD")
                                            setDateString(dateString)
                                        }} />
                                    </div>
                                </Overlay>
                            </span>
                        </LabelledControl>


                    </span>

                    {_.isNil(content) && <>Loading...</>}

                    {/* {!_.isNil(content) && (selectedFormat === "pdf") && <embed width="100%" height="600px" type="application/pdf" src={`data:application/pdf;base64,${content}`} />} */}
                    {!_.isNil(content) && (selectedFormat === "html") && <iframe style={{ height: "600px" }} width="100%" src={"data:text/html," + encodeURIComponent(content)} />}
                    {/* {!_.isNil(content) && (selectedFormat === "csv") && <div>{content}</div>} */}
                    {!_.isNil(content) && <>
                        <span style={{ display: "flex", gap: "20px" }}>

                            <Button variant="outline-secondary" onClick={async () => {
                                const result = await getReport("pdf")
                                const linkSource = 'data:application/pdf;base64,' + result.data.content
                                const downloadLink = document.createElement("a");
                                downloadLink.href = linkSource;
                                downloadLink.download = result.data.filename
                                downloadLink.click();

                            }}>Download PDF</Button>
                            {/* <Button variant="outline-secondary" onClick={async () => {
                                const result = await getReport("csv")
                                const linkSource = 'data:text/csv;base64,' + btoa(result.data.content)
                                const downloadLink = document.createElement("a");
                                downloadLink.href = linkSource;
                                downloadLink.download = result.data.filename
                                downloadLink.click();
                            }}>Download CSV</Button> */}
{/* 
                            <Button variant="outline-secondary" onClick={async () => {
                                const result = await getReport("html")
                                const dataURL = `data:text/html,${result.data.content}`
                                var iframe = `<iframe style="border: none;" width='100%' height='100%' src='${dataURL}'></iframe>`
                                var x = window.open();
                                x?.document.open();
                                x?.document.write(result.data.content);
                                x?.document.close();
                                
                            }}>Show HTML</Button> */}

                        </span>
                    </>}


                </Card.Body>
            </Card>
        </PageState>
    )
}



export default withRoleRouterFunc(Reports)
