import * as React from "react"

import { Role } from "../../../config/role"
import { DropdownButton, MenuItem, FormControl, Button, Card, Modal, Alert } from "../../wrappers"
import { currentDatabaseRef } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import CopyToClipboard from "react-copy-to-clipboard"
import { ImportIntegrationEdit } from "./ImportIntegrationEdit"
import { RoleRouterProps, withRoleRouter } from '../../../routes'
import { Clipboard2Check } from "react-bootstrap-icons"
import { InputGroup } from "react-bootstrap"
import * as _ from "lodash"
import { child, DatabaseReference, get, off, onValue, remove, set } from "firebase/database"

interface ImportIntegrationDeleteRequest {
    type: string
    id: string
}

interface ImportIntegrationsState {
    addIntegrationType?: ImportIntegrationType
    editIntegrationId?: string
    apiKeysConfiguration?: any
    configurations?: any
    deleteRequest?: ImportIntegrationDeleteRequest
    copied: boolean
    showAsync: boolean
}

export enum ImportIntegrationType {
    products = "products",
    discountCampaigns = "discount_campaigns",
    recommendations = "recommendations",
    stock = "stock",
    tags = "tags",
    productGroups = "product_groups",
    attributes = "attributes",
    customerAttributes = "customer_attributes",
    attributeGroups = "attribute_groups",
    folders = "folders",
    tabs = "tabs",
    recommendationCategories = "recommendation_categories",
    facets = "facets",
    productRules = "product_rules",
    incomingOrders = "external_orders",
    expenses = "expenses",
    productAssets = "product_assets",
    configurableFrontPage = "configurable_front_page",
    customers = "customers",
    outgoingOrderStateChanges = "outgoing_order_state_changes",
    loyaltyPointEvents = "loyalty_point_events",
    stockReplenishmentSuggestions = "stock_replenishment_suggestions"
}

export function getIntegrationName(type: ImportIntegrationType): string {
    switch (type) {
        case ImportIntegrationType.products:
            return "Product"
        case ImportIntegrationType.productAssets:
            return "Product assets"
        case ImportIntegrationType.discountCampaigns:
            return "Discount campaign"
        case ImportIntegrationType.recommendations:
            return "Recommendation"
        case ImportIntegrationType.stock:
            return "Stock"
        case ImportIntegrationType.tags:
            return "Tag"
        case ImportIntegrationType.attributes:
            return "Product Attribute"
        case ImportIntegrationType.customerAttributes:
            return "Customer Attribute"
        case ImportIntegrationType.attributeGroups:
            return "Product Attribute group"
        case ImportIntegrationType.productGroups:
            return "Product group"
        case ImportIntegrationType.folders:
            return "Folders"
        case ImportIntegrationType.tabs:
            return "Tabs"
        case ImportIntegrationType.recommendationCategories:
            return "Recommendation categories"
        case ImportIntegrationType.facets:
            return "Facets"
        case ImportIntegrationType.productRules:
            return "Product rules"
        case ImportIntegrationType.incomingOrders:
            return "Incoming orders"
        case ImportIntegrationType.outgoingOrderStateChanges:
            return "Outgoing order state changes"
        case ImportIntegrationType.expenses:
            return "Expenses"
        case ImportIntegrationType.configurableFrontPage:
            return "Configurable front page"
        case ImportIntegrationType.customers:
            return "Customer"
        case ImportIntegrationType.loyaltyPointEvents:
            return "Loyalty point events"
        case ImportIntegrationType.stockReplenishmentSuggestions:
            return "Stock replenishment suggestions"
    }
}

class ImportIntegrations extends React.Component<RoleRouterProps, ImportIntegrationsState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            addIntegrationType: undefined,
            copied: false,
            showAsync: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const apiKeysRef = child(child(accountRef, "api_keys"), "import_integrations")
        const apiKeysSnap = await get(apiKeysRef)
        const apiKeysConfiguration = apiKeysSnap.val()
        const integrationConfigurationRef = child(accountRef, "configuration/import_integrations")
        const integrationConfigurations = (await get(integrationConfigurationRef)).val()

        const asyncImportMetadataRef = child(accountRef, "import_integrations/async/system/metadata")
        const asyncImportMetadataSnap = await get(asyncImportMetadataRef)
        this.setState({ showAsync: asyncImportMetadataSnap.exists(), apiKeysConfiguration: apiKeysConfiguration, configurations: integrationConfigurations })

        onValue(apiKeysRef, snap => {
            this.setState({ apiKeysConfiguration: snap.val() })
        })

        onValue(integrationConfigurationRef, snap => {
            this.setState({ configurations: snap.val() })
        })

    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const importIntegrationKeysRef = child(child(accountRef, "api_keys"), "import_integrations")
        const integrationConfigurationRef = child(accountRef, "configuration/import_integrations")
        off(importIntegrationKeysRef)
        off(integrationConfigurationRef)
    }

    availableIntegrations(): ImportIntegrationType[] {
        return [
            ImportIntegrationType.products,
            ImportIntegrationType.discountCampaigns,
            ImportIntegrationType.recommendations,
            ImportIntegrationType.stock,
            ImportIntegrationType.tags,
            ImportIntegrationType.productGroups,
            ImportIntegrationType.attributes,
            ImportIntegrationType.attributeGroups,
            ImportIntegrationType.folders,
            ImportIntegrationType.tabs,
            ImportIntegrationType.recommendationCategories,
            ImportIntegrationType.facets,
            ImportIntegrationType.productRules,
            ImportIntegrationType.incomingOrders,
            ImportIntegrationType.outgoingOrderStateChanges,
            ImportIntegrationType.expenses,
            ImportIntegrationType.productAssets,
            ImportIntegrationType.configurableFrontPage,
            ImportIntegrationType.customers,
            ImportIntegrationType.customerAttributes,
            ImportIntegrationType.loyaltyPointEvents,
            ImportIntegrationType.stockReplenishmentSuggestions
        ]
    }

    async deleteIntegration(deleteRequest?: ImportIntegrationDeleteRequest) {
        if (deleteRequest === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationRef = child(child(child(accountRef, "configuration/import_integrations"), deleteRequest.type), deleteRequest.id)
        await set(child(integrationRef, "deactivated"), true)

        const apiKeysRef = child(child(child(accountRef, "api_keys/import_integrations"), deleteRequest.type), deleteRequest.id)
        const integration = ((this.state.apiKeysConfiguration || {})[deleteRequest.type] || {})[deleteRequest.id]
        for (const apiKey in integration) {
            const apiKeyRef = child(child(apiKeysRef, apiKey), "metadata")
            await set(child(apiKeyRef, "active"), false)
        }

        this.setState({ deleteRequest: undefined })
    }

    integrationRef(integrationType: string): DatabaseReference {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        return child(accountRef, `api_keys/import_integrations/${integrationType}`)
    }

    async reactivate(integrationId: string, integrationType: string) {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationRef = child(child(child(accountRef, "configuration/import_integrations"), integrationType), integrationId)
        await remove(child(integrationRef, "deactivated"))
        const apiKeysRef = child(child(child(accountRef, "api_keys/import_integrations"), integrationType), integrationId)
        const integration = ((this.state.apiKeysConfiguration || {})[integrationType] || {})[integrationId]
        for (const apiKey in integration) {
            const apiKeyRef = child(child(apiKeysRef, apiKey), "metadata")
            await set(child(apiKeyRef, "active"), true)
        }
    }

    renderExistingIntegrations() {
        const account = this.props.role.account_id
        const base = `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/imports`

        const apiKeysConfiguration = this.state.apiKeysConfiguration
        if (!apiKeysConfiguration) { return null }
        const configurations = this.state.configurations || {}
        return (
            <div>
                {
                    Object.keys(configurations).map((integrationTypeKey: string) => {
                        const apiKeys = apiKeysConfiguration[integrationTypeKey]
                        const integrationTypesConfig = configurations[integrationTypeKey] || {}
                        return (
                            <Card className="my-4" key={integrationTypeKey}>
                                <Card.Header>
                                    {getIntegrationName(integrationTypeKey as ImportIntegrationType)} import integrations
                                </Card.Header>

                                <Card.Body>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th>Integration Id</th>
                                                <th>Integration Name</th>
                                                <th>Endpoint</th>
                                                <th>Edit</th>
                                                <th>Deactivate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(integrationTypesConfig).map((integrationKey: string) => {

                                                const integrationConfig = integrationTypesConfig[integrationKey] || {}
                                                const integrationName = integrationConfig.name || "-"
                                                const deactivated = integrationConfig.deactivated || false
                                                const triggerType = integrationConfig?.trigger?.type
                                                const isPolling = triggerType === "cron"
                                                let importUrl = getImportUrl(apiKeys, integrationKey, base, integrationTypeKey, account)

                                                return (
                                                    <tr
                                                        key={integrationKey}
                                                        onClick={() => {
                                                            if (deactivated) { return }
                                                            this.props.router.navigate(`/import_integration/${integrationTypeKey}/${integrationKey}`)
                                                        }}
                                                    >
                                                        <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationKey}</td>
                                                        <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationName}</td>
                                                        {!isPolling ?
                                                            (<td onClick={(event) => { event.stopPropagation() }}>
                                                                <CopyToClipboard
                                                                    text={importUrl}
                                                                    onCopy={() => { this.setState({ copied: true }); setTimeout(() => { this.setState({ copied: false }) }, 5000) }}
                                                                >
                                                                    <InputGroup style={{ width: "100%" }}>
                                                                        <FormControl type="text" readOnly={true} value={importUrl} />
                                                                        <InputGroup.Text>
                                                                            <Clipboard2Check />
                                                                        </InputGroup.Text>
                                                                    </InputGroup>
                                                                </CopyToClipboard>
                                                            </td>) : <td><ManuallyRetryButton deactivated={deactivated} onClick={async () => {
                                                                await retriggerPollingIntegration(account, integrationTypeKey, integrationKey)
                                                            }}></ManuallyRetryButton></td>
                                                        }
                                                        <td className="narrow"><Button disabled={deactivated} variant="success" onClick={(event) => { event.stopPropagation(); this.setState({ editIntegrationId: integrationKey, addIntegrationType: integrationTypeKey as ImportIntegrationType }) }}>Edit</Button></td>
                                                        <td className="narrow">
                                                            {deactivated ?
                                                                <Button variant="warning" onClick={async (event) => { event.stopPropagation(); await this.reactivate(integrationKey, integrationTypeKey) }}>Reactivate</Button>
                                                                :
                                                                <Button variant="danger" onClick={(event) => { event.stopPropagation(); this.setState({ deleteRequest: { id: integrationKey, type: integrationTypeKey } }) }}>X</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </StripedTable>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </div>
        )
    }

    // The async integration type is not one you can create, but it is used
    // for marking that imports should happen asynchronously, so we must be able to inspect the queues
    // We show it if the 'metadata' node exists, meaning that an async import has occurred at some point
    renderAsyncMetaIntegration(): any | undefined {
        return this.state.showAsync && <Card className="my-4" key="async">
            <Card.Header>
                Asynchronous import integrations
            </Card.Header>

            <Card.Body>
                <StripedTable>
                    <thead>
                        <tr>
                            <th>Integration Id</th>
                            <th>Integration Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            key="system"
                            onClick={() => {
                                this.props.router.navigate(`/import_integration/async/system`)
                            }}
                        >
                            <td style={{ color:  "#333333" }}>default</td>
                            <td style={{ color:  "#333333" }}>Built-in async import</td>
                        </tr>
                    </tbody>
                </StripedTable>
            </Card.Body>
        </Card>

    }

    editedIntegration(): any | undefined {
        const integrationType = this.state.addIntegrationType
        const integrationId = this.state.editIntegrationId
        const configurations = this.state.configurations
        if (integrationType === undefined) { return }
        if (integrationId === undefined) { return }
        if (configurations === undefined) { return }
        return (configurations[integrationType] || {})[integrationId] || {}
    }

    render() {
        return (
            <div>
                {this.state.copied ? <Alert variant="success">Integration URL copied to clipboard.</Alert> : null}
                <br /><br />
                {this.state.addIntegrationType ? (
                    <ImportIntegrationEdit
                        role={this.props.role}
                        integrationType={this.state.addIntegrationType}
                        existingIntegrationId={this.state.editIntegrationId}
                        existingIntegration={this.editedIntegration()}
                        editComplete={() => { this.setState({ addIntegrationType: undefined, editIntegrationId: undefined }) }}
                    />
                )
                    :
                    (
                        <DropdownButton
                            variant="primary"
                            title="Add import integration"
                            id="add_integration"
                            onSelect={(event: any) => {
                                this.setState({ addIntegrationType: event as ImportIntegrationType })
                            }}
                        >
                            {
                                this.availableIntegrations().map(integrationKey => {
                                    return <MenuItem key={integrationKey} eventKey={integrationKey}>Add {getIntegrationName(integrationKey).toLowerCase() || "-"} integration</MenuItem>
                                })
                            }
                        </DropdownButton>
                    )
                }

                {this.state.deleteRequest ? (
                    <Modal show={true} key="b">
                        <Modal.Header>
                            <Modal.Title>Deactivate integration</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>Are you certain that you wish to deactivate the integration?</Modal.Body>

                        <Modal.Footer>
                            <Button onClick={() => { this.setState({ deleteRequest: undefined }) }}>Cancel</Button>
                            <Button variant="danger" onClick={async () => { await this.deleteIntegration(this.state.deleteRequest) }}>Deactivate</Button>
                        </Modal.Footer>
                    </Modal>
                ) : null}

                <br /><br /><br />
                {this.renderExistingIntegrations()}
                {this.renderAsyncMetaIntegration()}

            </div>
        )
    }
}

interface ManuallyRetryButtonProps {
    deactivated: boolean
    onClick: () => void
}

interface ManuallyRetryButtonState {
    allowRetry: boolean
}

export class ManuallyRetryButton extends React.Component<ManuallyRetryButtonProps, ManuallyRetryButtonState> {
    allowRetry: boolean = true
    // Ten minute limit
    private countdown = 600

    constructor(props: ManuallyRetryButtonProps) {
        super(props)

        this.state = {
            allowRetry: true
        }
    }

    render() {
        return <Button disabled={this.props.deactivated || !this.state.allowRetry} variant="warning" onClick={async (event) => {
            event.stopPropagation()
            await this.props.onClick()
            this.startTimer()
        }}>Trigger manually</Button>
    }

    startTimer() {
        this.setState({ allowRetry: false })
        const intervalId = setInterval(() => {
            this.countdown = this.countdown - 1;
            if (this.countdown === 0) {
                this.setState({ allowRetry: true })
                clearInterval(intervalId)
            }
        }, 1000)
    }
}

function getImportUrl(apiKeys: any, integrationKey: string, base: string, integrationTypeKey: string, account: string) {
    let api: any = undefined
    if (!_.isNil(apiKeys)) {
        api = apiKeys[integrationKey]
    }
    let importUrl = ""
    if (!_.isNil(api)) {
        // We are hiding the fact that multiple keys may exist for the same integration
        const firstAPIKey = Object.keys(api)[0]
        importUrl = `${base}/${integrationTypeKey}?account=${account}&apikey=${firstAPIKey}&integration=${integrationKey}`
    }
    return importUrl
}

async function retriggerPollingIntegration(account: string, integrationTypeKey: string, integrationKey: string) {
    const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
    const retriggerPath = child(accountRef, `import_integrations/${integrationTypeKey}/cron/${integrationKey}/trigger`)
    const value = await get(retriggerPath)
    // If value exists it means a retrigger is in progress, therefore ignore.
    if (value.exists()) { return }
    const message = "Are you sure you want to retrigger the import?"
    if (window.confirm(message)) {
        await set(retriggerPath, true)
    }
}

export default withRoleRouter(ImportIntegrations)
