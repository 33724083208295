import * as React from "react"
import { ModuleConfiguration } from "./ModuleConfiguration"
import {
    cloneDeep,
    Dictionary,
} from "lodash"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { RoleProps, withRole } from "../../../routes"
import { child, get } from "firebase/database"

export interface ModuleDefinition {
    name: string
    id: string
    description: string
    always_enabled?: boolean
    update_properties?: string[]
    [x: string]: any
}

interface ModulesConfigurationState {
    loaded: boolean
    modules: any
}

class ModulesConfiguration extends React.Component<RoleProps, ModulesConfigurationState> {
    allModules: Dictionary<ModuleDefinition> = {
        customer_lookup: { name: "Customer Lookup", id: "customer_lookup", description: "With the Customer Lookup module you can provide an integration for looking up a single, specific customer by id, phone, email, name, etc. The customer information can be used for discount campaign rules that only trigger for members - and for pre-filling shipping details for e-com sales." },
        stock: { name: "Stock", id: "stock", description: "With the Stock module you get automatic stock adjustments on sales and returns. You get access to the Stock app where you can perform goods reception and inventory. And you can see the available stock in the Product Details screen in the POS." },
        newsletter: { name: "Newsletter Signup", id: "newsletter", description: "The Newsletter Signup module allows your customers to sign up to your newsletter using services like Mailchimp." },
        orders: { name: "Orders", id: "orders", description: "The Orders module enables the ability to setup Reserve & Collect, Click & Collect, Ship from Store and Inventory transfer on each shop. To enable/disable the different order handling capabilities, go to the shop configuration of your selected shop. This allows for import of pre-paid (Click & Collect) and non pre-paid (Reserve & Collect) external orders as well as prepaid orders with shipping. Orders can can be sent from an e-commerce platform to a particular shop in Ka-ching for either pickup (Click & Collect), payment and pickup (Reserve & Collect) or shipping by the store (Ship from Store)." },
        shopify_integration: { name: "Shopify Integration", id: "shopify_integration", description: "The Shopify Integration allows several ways of connecting Ka-ching to your Shopify webshop. You can configure it in the Advanced part of Ka-ching Back Office." },
        giftcard: { name: "Gift card (via integration)", id: "giftcard", description: "The gift card module enables you to use an external gift card provider to enable payment with gift cards. Since Ka-ching is using an external service to provide the functionality an integration needs to be setup. Currently we don't provide any standard integrations so contact your favorite Ka-ching representative if you're interested in using gift cards with Ka-ching" },
        voucher: { name: "Voucher (via integration)", id: "voucher", description: "The voucher module enables you to use an external voucher provider to enable returns to and payment with vouchers. Since Ka-ching is using an external service to provide the functionality an integration needs to be setup. Currently we don't provide any standard integrations so contact your favorite Ka-ching representative if you're interested in using vouchers with Ka-ching" },
        tas_landing_page: { name: "TAS landing page", id: "tas_landing_page", description: "With the TAS landing page module enabled, the cashier will see a 'splash screen' after every sale - prompting the cashier to engage with the customer or get started with finding products." },
        tas_notifications: { name: "TAS notifications", id: "tas_notifications", description: "The TAS notifications module enables you to provide notifications to the cashier to remind them of business critical decisions." },
        expenses: { name: "Expenses", id: "expenses", description: "The Expenses module allows you to create or import expense items that can be selected from POS." },
        ml_recommender: { name: "Machine Learning Recommendations", id: "ml_recommender", description: "The Machine Learning Recommendations module will recommend items matching the current basket based on the purchase habbits of previous customers." },
        configurable_front_page: { name: "Configurable front page", id: "configurable_front_page", description: "The configurable front page allows you to set up sections with shortcuts, curated products and most popular products in any order or fashion you like" },
        mobile_pay: { name: "MobilePay", id: "mobile_pay", description: "The MobilePay module enables you to accept the MobilePay payment type. Currently only for the DKK currency.", update_properties: ["enabled"] },
        second_screen_basket: { name: "Second Screen Basket", id: "second_screen_basket", always_enabled: true, description: "The Basket module is the main Second Screen module and allows your customers to follow along on the Second Screen as you add items to the basket. If you upload a logo, it will be shown when the basket is empty." },
        print_shelf_label: { name: "Product Labels", id: "product_labels", description: "The product labels module enable printing of product labels in the POS app." },
        gift_receipts: { name: "Gift receipts", id: "gift_receipts", description: "The gift receipts module allows you to print gift receipts upon completing a sale in the POS app." },
        employee_purchase: { name: "Employee purchase", id: "employee_purchase", description: "The Employee purchase module allows you to mark sales as employee purchases. This can be used for triggering discounts and for aggregating stats differently from other sales." },
        external_sales: { name: "External Sales", id: "external_sales", description: "The external sales modules allows you to show external sales (sales done outside the Ka-ching system) for customers in the POS app." },
        loyalty_club: { name: "Loyalty Club", id: "loyalty_club", description: "The loyalty club module allows customers to earn and redeem loyalty points through our POS system. These points can be earned by making purchases and can be redeemed for discounts. This feature helps to increase customer retention and loyalty by incentivizing repeat business." },
        deposit_box: { name: "Deposit box", id: "deposit_box", description: "The deposit box module allows you to move cash between register and deposit box as well as between deposit box and bank. It also allows for an extra deposit box reconciliation flow." },
        b2b: { name: "B2B", id: "b2b", description: "The B2B module adds B2B-specific fields for customers and B2B-specific shop configuration overrides." },
        franchise: { name: "Franchise", id: "franchise", description: "The franchise module allows franchisees to look up products in the franchise owner's system. Below you can select filters to be applied on products imported to the franchisees system." },
    }

    moduleOrder = [
        this.allModules.stock,
        this.allModules.shopify_integration,
        this.allModules.giftcard,
        this.allModules.voucher,
        this.allModules.customer_lookup,
        this.allModules.newsletter,
        this.allModules.orders,
        this.allModules.tas_landing_page,
        this.allModules.tas_notifications,
        this.allModules.expenses,
        this.allModules.ml_recommender,
        this.allModules.configurable_front_page,
        this.allModules.mobile_pay,
        this.allModules.print_shelf_label,
        this.allModules.gift_receipts,
        this.allModules.employee_purchase,
        this.allModules.second_screen_basket,
        this.allModules.external_sales,
        this.allModules.loyalty_club,
        this.allModules.deposit_box,
        this.allModules.b2b,
        this.allModules.franchise
    ]

    constructor(props: RoleProps) {
        super(props)
        this.state = {
            modules: {},
            loaded: false,
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const moduleConfigRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/modules`)
        this.setState({ loaded: false })
        const snapshot = await get(moduleConfigRef)
        this.setState({ modules: snapshot.exists() ? snapshot.val() : {}, loaded: true })
    }

    getConfiguration(moduleName: string): any {
        if (!this.state.modules) {
            return {}
        }
        return cloneDeep(this.state.modules[moduleName] ?? {})
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="module configuration">
                {
                    this.moduleOrder.map(m => {
                        return (
                            <ModuleConfiguration key={m.id} role={this.props.role} moduleDefinition={m} moduleData={this.getConfiguration(m.id)} />
                        )
                    })
                }
            </PageState>
        )
    }
}

export default withRole(ModulesConfiguration)
