import * as React from "react"
import { FormGroup, Col, FormControl, HelpBlock, DescriptionCol } from "./wrappers"
import _, { isNil } from "lodash"
import { Row } from "react-bootstrap"
import { CollectionReference, doc, getDoc } from "firebase/firestore"
import { child, DatabaseReference, get } from "firebase/database"

export interface ValidatingIdEntryControlState {
    newOverrideId: string | null
    idInUse: boolean
}

export interface ValidatingIdEntryControlProps {
    collectionRef?: DatabaseReference | CollectionReference
    isNew: boolean
    typeName: string
    descriptionName?: string
    identifierSource: string | null
    existingIdentifier: string
    handleIdChange: (id: string, valid: boolean) => void
    invalidIdentifiers?: string[]
    showExistingIdentifier?: boolean
}

export class ValidatingIdEntryControl extends React.Component<ValidatingIdEntryControlProps, ValidatingIdEntryControlState> {
    constructor(props: ValidatingIdEntryControlProps) {
        super(props)
        this.state = {
            newOverrideId: null,
            idInUse: false
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps: ValidatingIdEntryControlProps) {
        if (nextProps.identifierSource !== this.props.identifierSource) {
            const isValidId = await this.isValidId(nextProps)
            const id = this.newIdentifier(nextProps)
            this.props.handleIdChange(id, isValidId)
        }
    }

    newIdentifier(props: ValidatingIdEntryControlProps) {
        if (!props.isNew) {
            return props.existingIdentifier
        }
        if (!isNil(this.state.newOverrideId)) {
            return this.state.newOverrideId.trim()
        } else {
            return this.generatedIdentifier(props).trim()
        }
    }

    generatedIdentifier(props: ValidatingIdEntryControlProps) {
        if (!props.identifierSource) {
            return ""
        }
        let value = props.identifierSource
            .toLowerCase()
            .replace(/ø/g, "oe")
            .replace(/æ/g, "ae")
            .replace(/å/g, "aa")
            .replace(/\W/g, "_") || ""

        if (value.startsWith("__") && value.endsWith("__")) {
            value = value.replace(/_+$/, "_")
        }
        return value
    }

    async isValidId(props: ValidatingIdEntryControlProps): Promise<boolean> {
        if (!this.props.isNew) {
            return true
        }
        this.setState({ idInUse: false })
        const idToTest = this.newIdentifier(props)
        if (idToTest.length === 0) {
            return true
        }
        if ((this.props.invalidIdentifiers ?? []).includes(idToTest)) {
            this.setState({ idInUse: true })
            return false
        }
        const idInUse = await this.idInUse(idToTest)
        // if value has changed while we were fetching, don't do anything
        if (this.newIdentifier(props) !== idToTest) {
            return true
        }
        this.setState({ idInUse: idInUse })
        return !idInUse
    }

    private async idInUse(idToTest: string) {
        if (_.isNil(this.props.collectionRef)) {
            return false
        } else if (this.props.collectionRef instanceof CollectionReference) {
            const ref = doc(this.props.collectionRef, idToTest)
            const snapshot = await getDoc(ref)
            return snapshot.exists()
        } else {
            const itemRef = child(this.props.collectionRef, idToTest)
            const snap = await get(itemRef)
            return snap.exists()
        }
    }

    handleIdChange(event: any) {
        const target = event.target
        let value: string = target.value

        let overrideId: string | null = null

        // * is also included since it's used in some composite keys when appending product id and variant id. Stats and stock count use it.
        const invalidCharacters = [".", "[", "]", "/", "#", "$", "*"]
        for (const character of invalidCharacters) {
            value = value.replace(new RegExp(`\\${character}`, "g"), "_")
        }

        if (value.startsWith("__") && value.endsWith("__")) {
            value = value.replace(/_+$/, "_")
        }

        if (value === this.generatedIdentifier(this.props)) {
            overrideId = null
        } else {
            overrideId = value
        }

        this.setState({ newOverrideId: overrideId }, async () => {
            const isValidId = await this.isValidId(this.props)
            this.props.handleIdChange(this.newIdentifier(this.props), isValidId)
        })
    }

    renderNew() {
        return (
            <FormGroup className="mb-3" as={Row} validationState={this.state.idInUse ? "error" : null}>
                <DescriptionCol sm={2}> {!_.isNil(this.props.descriptionName) ? this.props.descriptionName : "Identifier"} </DescriptionCol>
                <Col sm={10}>
                    <FormControl
                        type="text"
                        name={this.props.typeName}
                        value={this.newIdentifier(this.props)}
                        placeholder={`Enter ${this.props.typeName} identifier`}
                        onChange={event => { this.handleIdChange(event) }}
                        autoComplete="off"
                    />

                    {this.state.idInUse ? <div><br></br> <HelpBlock>Identifier &apos;{this.newIdentifier(this.props)}&apos; is already in use or invalid</HelpBlock> </div> : null}
                </Col>
            </FormGroup>
        )
    }

    renderExisting() {
        return (
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={2}>Identifier</DescriptionCol>
                <Col sm={10}>
                    <FormControl
                        type="text"
                        disabled={true}
                        name={this.props.typeName}
                        value={this.newIdentifier(this.props)}
                        placeholder={`Enter ${this.props.typeName} identifier`}
                        autoComplete="off"
                    />
                </Col>
            </FormGroup>
        )
    }

    render() {
        if (this.props.isNew) {
            return this.renderNew()
        } else if (this.props.showExistingIdentifier === true) {
            return this.renderExisting()
        } else {
            return null
        }
    }
}
