import { L10nString } from "src/helpers/L10n"

export class PurchaseType {
    identifier: string
    name: L10nString

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.identifier = json.identifier
    }

    json(): any {
        const value: any = {
            name: this.name.json(),
            identifier: this.identifier,
        }
        return value
    }
}