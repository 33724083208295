import * as _ from "lodash"

import { firestore, currentDatabaseRef } from "../../../config/constants"
import { mapValuesToModelClass } from "../../../helpers/jsMapValue"
import { StockCountIndex } from "../../../models/StockCountModels"
import { StockCountListItem } from "./StockCountListModels"
import { Globals } from "../../../helpers/globals"
import { StockCountFilter } from "../../../models/StockCountFilter"
import { stockCountFilterToJSON, StockCountRequest } from "../../../models/StockCountRequest"
import { Comparison } from "../DiscountRules/AppliesToSelector"
import { getFunctions, httpsCallable } from "firebase/functions"
import { collection, DocumentData, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter } from "firebase/firestore"
import { child, get, limitToLast, off, onValue, orderByKey, query as dbQuery, endAt } from "firebase/database"

const pageLimit = 25

interface AttributeFilter {
    value: string
    comparison: Comparison
}

export class StockCountListViewModel {

    // Props

    private accountId: string
    private currentStockCountId?: string
    private shopId: string
    private stockCountIdPagingStack: string[]
    private nextDisabled: boolean
    private page: number
    private previousDisabled: boolean

    // Outputs

    didCompleteInitialLoad?: (past: StockCountListItem[], current?: StockCountListItem) => void
    didLoadPastStockCounts?: (past: StockCountListItem[]) => void
    didCreateNewStockCount?: (current?: StockCountIndex, errorMessage?: string) => void

    // Constructor

    constructor(accountId: string, shopId: string) {
        this.accountId = accountId
        this.shopId = shopId
        this.stockCountIdPagingStack = []
        this.nextDisabled = true
        this.previousDisabled = true
        this.page = 0
    }

    // Pubic methods

    startInitialLoad() {
        this.page = 0
        this.stockCountIdPagingStack = []

        this.loadCurrentStockCount()
            .then((current) => {
                if (current) {
                    this.currentStockCountId = current.id
                }
                return Promise.all([current, this.loadPastStockCounts()])
            })
            .then(([current, past]) => {
                if (this.didCompleteInitialLoad) {
                    this.didCompleteInitialLoad(past, current)
                }
            })
            .catch((error) => { console.log(error) })
    }

    startLoadOfNextPastStockCounts() {
        this.loadPastStockCounts()
            .then((stockCounts) => {
                if (this.didLoadPastStockCounts) {
                    this.didLoadPastStockCounts(stockCounts)
                }
            })
            .catch((error) => { console.log(error) })
    }

    startLoadOfPreviousPastStockCounts() {
        this.loadPreviousPastStockCounts()
            .then((stockCounts) => {
                if (this.didLoadPastStockCounts) {
                    this.didLoadPastStockCounts(stockCounts)
                }
            })
            .catch((error) => { console.log(error) })
    }

    isNextDisabled(): boolean {
        return this.nextDisabled
    }

    isPreviousDisabled(): boolean {
        return this.previousDisabled
    }

    async createNewStockCountFromRequest(request: StockCountRequest, callback: (total: number, count: number, filtered: number) => void) {
        await this.createNewStockCount(request.name, request.filter, callback, request.id, request.dueDate)
    }

    async cancelStockCountRequest(request: StockCountRequest) {
        const cancelRequest = httpsCallable(getFunctions(), "StockCount-client")
        const args: any = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "cancel-request",
            request_id: request.id
        }
        const value: any = await cancelRequest(args)
        const success = value.data.success
        if (!success) {
            throw new Error("Could not cancel stock count request")
        }
    }

    async createNewStockCount(
        name: string,
        filter: StockCountFilter,
        callback: (total: number, count: number, filtered: number) => void,
        requestId?: string,
        requestDueDate?: Date
    ) {
        const open = httpsCallable(getFunctions(), "StockCount-client")
        const args: any = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "open",
            name: name
        }
        if (requestId !== undefined) {
            args.request_id = requestId
        }
        if (requestDueDate !== undefined) {
            args.request_due_date = requestDueDate
        }
        const filters: any = stockCountFilterToJSON(filter)
        if (!_.isNil(filter.filterAttributes) && filter.filterAttributes.length > 0) {
            const attributes = filter.filterAttributes
            // The logic that we want is that if the same attribute is present with more options
            // then we want to include products that have either of the options. So we transform the
            // list of attributes to a dictionary from attribute id to array of options:
            const attributeDict: _.Dictionary<AttributeFilter[]> = {}
            for (const attribute of attributes) {
                const existing = attributeDict[attribute.attributeId] ?? []
                existing.push({ value: attribute.optionId, comparison: attribute.comparison ?? "==" })
                attributeDict[attribute.attributeId] = existing
            }

            // Fetch products in batches of 100
            // Find matches on products (use product id only, no variant id)
            // and variants (use product id and variant)
            // call callback

            const marketId = await Globals.getMarket(this.shopId)

            let startAfterSnap: QueryDocumentSnapshot<DocumentData> | undefined
            const queryLimit = 100
            // Add a value to ensure that empty array doesn't get serialized as a missing filter.
            const keepProducts: any[] = [{ foo: "dummy" }]
            let count = 0
            let filtered = 0
            let notDone = true
            const queryPath = `accounts/${this.accountId}/inventory/${marketId}.pos/product_search_index`
            // The 'count' API has not been ported to the 'compat' libraries, so we'll need to update
            // to the modular firebase APIs to use this.
            // const totalCountSnap = await get(firestore.collection(queryPath).count())
            const totalCount = 0
            while (notDone) {
                let q = query(collection(firestore, queryPath),
                    orderBy("product.id"),
                    limit(queryLimit)
                )
                if (!_.isNil(startAfterSnap)) {
                    q = query(q, startAfter(startAfterSnap))
                }
                const snap = await getDocs(q)
                startAfterSnap = snap.docs[snap.docs.length - 1]

                for (const docSnap of snap.docs) {
                    const product = docSnap.data() ?? {}
                    let keepProduct = true
                    for (const attributeId in attributeDict) {
                        const options = attributeDict[attributeId]
                        const productValue = product.product?.attributes?.[attributeId]
                        if (_.isNil(productValue)) {
                            keepProduct = false
                            break
                        }

                        let optionsMatch = false
                        for (const option of options) {
                            let compareValue = productValue
                            // Workaround for discrepancy between attribute definition and attribute values
                            // for Basic & More. The attribute is a string but the imported values are numbers.
                            // They are ids, so they should likely be strings in order to be able to represent
                            // prefixed zeroes like "0012". But they are not, so we perform this workaround to help.
                            if (typeof option.value === "string" && typeof productValue !== "string") {
                                compareValue = `${productValue}`
                            }
                            switch (option.comparison) {
                                case "==": {
                                    optionsMatch = compareValue === option.value
                                    break
                                }
                                case "<": {
                                    optionsMatch = compareValue < option.value
                                    break
                                }
                                case ">": {
                                    optionsMatch = compareValue > option.value
                                    break
                                }
                                case "<=": {
                                    optionsMatch = compareValue <= option.value
                                    break
                                }
                                case ">=": {
                                    optionsMatch = compareValue >= option.value
                                    break
                                }
                            }
                            if (optionsMatch) {
                                break
                            }
                        }
                        if (!optionsMatch) {
                            keepProduct = false
                        }
                    }
                    if (keepProduct) {
                        keepProducts.push({ product_id: product.product.id })
                        filtered += 1
                    } else {
                        // If product has variants, also loop over those
                        let anyVariantsKept = false
                        for (const variant of product.product?.variants ?? []) {
                            let keepVariant = true
                            for (const attributeId in attributeDict) {
                                const options = attributeDict[attributeId]
                                const variantValue = variant.attributes?.[attributeId]
                                if (_.isNil(variantValue)) {
                                    keepVariant = false
                                    break
                                }

                                if (!options.includes(variantValue)) {
                                    keepVariant = false
                                    break
                                }
                            }
                            if (keepVariant) {
                                anyVariantsKept = true
                                keepProducts.push({ product_id: product.product.id, variant_id: variant.id })
                            }
                        }
                        if (anyVariantsKept) {
                            filtered += 1
                        }

                    }
                    count += 1
                }

                callback(totalCount, count, filtered)

                if (snap.docs.length < queryLimit) {
                    notDone = false
                }
            }
            filters.only_products = keepProducts
            // We have a dummy value, so length <= 1 means no filter matches
            if (keepProducts.length <= 1) {
                if (this.didCreateNewStockCount) {
                    const message = "The specified filters did not match any products in stock"
                    this.didCreateNewStockCount(undefined, message)
                }
                return
            }
        }

        if (Object.keys(filters).length > 0) {
            args.filters = filters
        }

        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory`

        const subscription = onValue(child(currentDatabaseRef(), `${path}/stock_counts/current_count`), snapshot => {
            if (snapshot === null) { return }
            if (!snapshot.exists()) { return }
            if (this.didCreateNewStockCount) {
                this.didCreateNewStockCount(snapshot.val(), undefined)
            }
            off(child(currentDatabaseRef(), `${path}/stock_counts/current_count`), "value", subscription)
        })

        try {
            const value: any = await open(args)
            const success = value.data.success
            const current_count_key = value.data.current_count_key
            if (!success || !current_count_key) {
                throw new Error("Could not create new stock")
            }
        } catch (error: any) {
            off(child(currentDatabaseRef(), `${path}/stock_counts/current_count`), "value", subscription)
            if (this.didCreateNewStockCount) {
                let message = `Failed opening stock count: ${error.message}`
                if (error.message === "empty-count") {
                    message = "The specified filters did not match any products in stock"
                }
                this.didCreateNewStockCount(undefined, message)
            }
        }
    }

    // Private methods

    private async loadPastStockCounts(): Promise<StockCountListItem[]> {
        const fromStockCountId = _.last(this.stockCountIdPagingStack)
        const stockCountListItems = await this.loadBatchOfPastStockCounts(fromStockCountId)
        return stockCountListItems
    }

    private async loadPreviousPastStockCounts(): Promise<StockCountListItem[]> {
        this.stockCountIdPagingStack.pop()
        this.stockCountIdPagingStack.pop()
        const stockCountListItems = await this.loadBatchOfPastStockCounts(_.last(this.stockCountIdPagingStack))
        return stockCountListItems
    }

    private async loadCurrentStockCount(): Promise<StockCountListItem | undefined> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory`

        const currentSnapshot = await get(child(currentDatabaseRef(), `${path}/stock_counts/current_count`))
        if (!currentSnapshot.exists()) {
            return undefined
        }

        const currentId: string = currentSnapshot.val()

        const snapshot = await get(child(currentDatabaseRef(), `${path}/stock_counts/count_index/${currentId}`))
        if (!snapshot.exists()) {
            return undefined
        }

        const stockCount = new StockCountIndex(snapshot.val())
        return new StockCountListItem(undefined, undefined, currentId, stockCount.name, stockCount.openedAt, stockCount.openedByEmail, undefined, stockCount.requestDueDate)
    }

    private async loadBatchOfPastStockCounts(fromStockCountId?: string): Promise<StockCountListItem[]> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/count_index`
        let refToFilteredPast = dbQuery(child(currentDatabaseRef(), path),  orderByKey(), limitToLast(pageLimit + 1))
        if (fromStockCountId) {
            refToFilteredPast = dbQuery(refToFilteredPast, endAt(fromStockCountId))
        }

        let stockCountListItems: StockCountListItem[] = []
        const snapshot = await get(refToFilteredPast)
        if (snapshot.exists()) {
            const stockCountsDict = mapValuesToModelClass(snapshot.val(), StockCountIndex)
            const stockCounts = Object.values(stockCountsDict || {})
            _.reverse(stockCounts)
            stockCountListItems = this.buildStockCountListItems(stockCounts)
        }

        // side effects - updating page state
        this.updateStockCountIdPagingStack(stockCountListItems, true)

        // filter out current
        if (this.currentStockCountId) {
            _.remove(stockCountListItems, (item: StockCountListItem) => {
                return item.id === this.currentStockCountId
            })
        }

        return _.take(stockCountListItems, pageLimit)
    }

    private buildStockCountListItems(stockCounts: StockCountIndex[]): StockCountListItem[] {
        return stockCounts.map((stockCount: StockCountIndex) => {
            return new StockCountListItem(stockCount.closedAt, stockCount.closedByEmail, stockCount.id, stockCount.name, stockCount.openedAt, stockCount.openedByEmail, stockCount.cancelled, stockCount.requestDueDate)
        })
    }

    private updateStockCountIdPagingStack(stockCountListItems: StockCountListItem[], isNext: boolean) {
        this.page += isNext ? 1 : -1
        this.nextDisabled = stockCountListItems.length < pageLimit + 1
        if (!this.nextDisabled) {
            const lastStockCount = _.head(_.takeRight(stockCountListItems, 2))
            if (lastStockCount) {
                this.stockCountIdPagingStack.push(lastStockCount.id)
            }
        }
        this.previousDisabled = this.page === 1
    }
}
