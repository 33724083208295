import * as React from "react"

import { Modal } from "../../wrappers"

import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Card, Dropdown, Form, Button } from "react-bootstrap"
import { L10nString, LanguageCode } from "../../../helpers/L10n"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute, AttributeOption, AttributeTypeKey } from "../../../models/Product"
import { AttributeSelection, Comparison, DeleteButtonSymbol } from "../DiscountRules/AppliesToSelector"
import { StripedTable } from "../../StripedTable"
import * as _ from "lodash"
import dayjs from "dayjs"
import { LabelledControl } from "../ProductEdit"
import { L10nFormControl } from "../../L10nFormControl"
import { DateEditor, DateTimeEditor } from "../AttributeSelection"
import * as LocalizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(LocalizedFormat as any)

export interface StockReportFilter {
    filterDate: Date
    filterAttributes?: AttributeSelection[]
    excludeZeroStockProducts: boolean
}

type ReportType = "counted_since_date" | "not_counted_since_date"
interface StockCountReportModalProps {
    mode: "stock_count_percentage" | "last_counted_shop"
    reportType: ReportType
    role: Role
    stockLocation: string
    openNew: (filter: StockReportFilter, filterDescription: string[]) => void
    cancel: () => void
}

interface StockCountReportModalState {
    loaded: boolean
    filterDate: Date
    excludeZeroStockProducts: boolean
    attributes: Attribute[]
    attributeSelection: AttributeSelection[]
    currentlyEditedText?: string
    tappedAttribute?: string
}

function comparisonDescription(comp: Comparison): string {
    switch (comp) {
        case "==": return "is"
        case "<": return "is before"
        case ">": return "is after"
        case "<=": return "is before or on"
        case ">=": return "is after or on"
    }
}

export function filterDescription(filter: StockReportFilter, reportType: ReportType, attributesDict?: _.Dictionary<Attribute>) {
    let description: string[] = []
    const filterAttrs = filter.filterAttributes ?? []
    const typeName: string = "report"
    const isIncluded: string = "will be included"
    if (filter.excludeZeroStockProducts) {
        description.push(`This ${typeName} will not consider products that have a stock value of 0`)
    }
    const dateDescription = dayjs(filter.filterDate).format("YYYY-MM-DD")

    description.push(`The report will show the products that are ${reportType == "counted_since_date" ? "counted since" : "uncounted since"}  ${dateDescription}.`)
    const filterAttrDict: _.Dictionary<AttributeSelection[]> = {}
    for (const attr of filterAttrs) {
        if (attr.optionId === "") { continue }
        const existing = filterAttrDict[attr.attributeId] ?? []
        existing.push(attr)
        filterAttrDict[attr.attributeId] = existing
    }

    if (Object.keys(filterAttrDict).length > 0) {
        const attributeDescriptions: string[] = []
        for (const attributeId in filterAttrDict) {
            const attribute = attributesDict?.[attributeId]
            const attributeName = attribute?.name.localized(null) ?? attributeId
            const options = filterAttrDict[attributeId].map(o => {
                let valueName = attribute?.type?.options?.[o.optionId]?.name.localized(null) ?? o.optionId

                if (!_.isNil(attribute?.type?.date_time)) {
                    valueName = dayjs(o.optionId).format("LLL")
                }
                if (!_.isNil(attribute?.type?.date)) {
                    valueName = dayjs(o.optionId).format("LL")
                }

                const compDesc = comparisonDescription(o.comparison ?? "==")
                return `${compDesc} <b>${valueName}</b>`
            })
            if (options.length > 1) {
                attributeDescriptions.push(`the attribute <b>${attributeName}</b> either (` + options.join(" OR ") + ")")
            } else {
                attributeDescriptions.push(`the attribute <b>${attributeName}</b> ${options[0]}`)
            }
        }
        if (attributeDescriptions.length === 1) {
            description.push("Only products where " + attributeDescriptions[0] + ` ${isIncluded} in the ${typeName}.`)
        } else {
            description.push("Only products where<br/>" + attributeDescriptions.join(" AND <br/>") + `<br/>${isIncluded} in the ${typeName}.`)
        }
    }
    return description
}

export class StockCountReportModal extends React.Component<StockCountReportModalProps, StockCountReportModalState> {

    // Constructor

    attributesObserver = new AttributeObserver(this.props.role.account_id)

    constructor(props: StockCountReportModalProps) {
        super(props)

        this.state = {
            loaded: false,
            filterDate: new Date(),
            attributes: [],
            attributeSelection: [],
            excludeZeroStockProducts: false
        }

        this.attributesObserver.attributesChangedCallback = () => {
            this.setState({ attributes: this.attributesObserver.attributesArray ?? [] })
        }
    }

    // Methods

    openButtonClicked() {
        let filter: StockReportFilter = {
            filterDate: this.state.filterDate,
            filterAttributes: this.filterAttributes(),
            excludeZeroStockProducts: this.state.excludeZeroStockProducts
        }
        this.props.openNew(filter, this.filterDescription())
    }

    filterAttributes() {
        const filterAttributes = _.cloneDeep(this.state.attributeSelection)
        // If we did not yet tap 'return' on the currently edited text, we still go ahead and use the current value
        return filterAttributes
    }

    filterDescription() {
        const filter: StockReportFilter = {
            filterAttributes: this.filterAttributes(),
            filterDate: this.state.filterDate,
            excludeZeroStockProducts: this.state.excludeZeroStockProducts
        }
        return filterDescription(filter, this.props.reportType, this.attributesObserver.attributesDict)
    }

    openButtonEnabled(): boolean {
        for (const selection of this.state.attributeSelection) {
            if (selection.optionId === "") {
                return false
            }
        }
        return true
    }

    cancelButtonClicked() {
        this.props.cancel()
    }

    selectAttribute(attributeId: string) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        clone.push({ attributeId: attributeId, optionId: "", comparison: "==" })

        this.setState({ attributeSelection: clone, currentlyEditedText: undefined })
    }

    selectAttributeValue(attributeId: string, value: string, comparison?: Comparison) {
        // Skip seleciton if it is already included in the list
        if (this.state.attributeSelection.find(selection => {
            return selection.attributeId === attributeId && selection.optionId === value
        }) !== undefined) {
            this.setState({ currentlyEditedText: undefined })
            return
        }
        const clone = _.cloneDeep(this.state.attributeSelection)
        clone.push({ attributeId: attributeId, optionId: value, comparison: comparison })
        this.setState({ attributeSelection: clone, currentlyEditedText: undefined })
    }

    updateCurrentTextAttribute(attributeId: string, value: string) {
        this.setState({ currentlyEditedText: value })
    }

    // Component

    async componentDidMount() {
        this.attributesObserver.start()

        this.setState({ loaded: true })
    }
    componentWillUnmount(): void {
        this.attributesObserver.stop()
    }

    allComps: Comparison[] = ["==", "<", ">", "<=", ">="]

    renderComparison(index: number) {
        const attr = this.state.attributeSelection[index]
        return <Dropdown onSelect={(value) => { this.setComparison(value ?? undefined as any, index) }}>
            <Dropdown.Toggle size="sm" variant="outline-dark">{comparisonDescription(attr.comparison ?? "==")}</Dropdown.Toggle>
            <Dropdown.Menu>
                {this.allComps.map(comp => {
                    return <Dropdown.Item key={comp} eventKey={comp}>{comparisonDescription(comp)}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>

    }

    renderDateValue(attributeId: string, value: string, index: number): JSX.Element {
        return <td width={350}><div className="pb-2">{this.renderComparison(index)}</div>

            <DateEditor show={this.state.tappedAttribute === attributeId} value={value} onChanged={(value) => {
                this.setAttribute(value, index)
            }} onShow={(shown) => {
                if (shown) {
                    this.setState({ tappedAttribute: attributeId })
                } else {
                    this.setState({ tappedAttribute: undefined })
                }
            }} />
        </td>
    }

    renderDateTimeValue(attributeId: string, value: string, index: number): JSX.Element {
        return <td width={350}><div className="pb-2">{this.renderComparison(index)}</div>
            <DateTimeEditor show={this.state.tappedAttribute === attributeId} value={value} onChanged={(value) => {
                this.setAttribute(value, index)
            }} onShow={(shown) => {
                if (shown) {
                    this.setState({ tappedAttribute: attributeId })
                } else {
                    this.setState({ tappedAttribute: undefined })
                }
            }} />
        </td>
    }

    renderOptionsValue(options: _.Dictionary<AttributeOption>, stringValue: string, attributeIndex: number): JSX.Element {
        const selectedOption = options[stringValue]
        let displayValue = stringValue
        if (!_.isNil(selectedOption)) {
            displayValue = selectedOption.name.localized(null)
        } else {
            displayValue = "Select a value"
        }
        return <td width={350}>
            <Dropdown
                onSelect={(selectedValue: any) => {
                    this.setAttribute(selectedValue, attributeIndex)
                }}>
                <Dropdown.Toggle
                    style={{ color: _.isNil(selectedOption) ? "#888888" : "black", width: "100%" }}
                    variant="outline-dark"
                    id="dropdown-attribute-value"
                >
                    {displayValue}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "100%" }}>
                    {Object.keys(options).map((optionKey) => {
                        const option = options[optionKey]
                        return <Dropdown.Item key={optionKey} eventKey={optionKey}>{option.name.localized(null)}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </td>
    }

    renderTextValue(value: string, index: number): JSX.Element {
        return <td width={350}>
            <L10nFormControl
                placeholder="Enter a value"
                l10n={new L10nString(value)}
                type="textarea"
                as="textarea"
                language={null}
                style={{ resize: "vertical", borderColor: "black", borderRadius: "0.375rem" }}
                onLocalizationChanged={l10n => {
                    if (_.isNil(l10n)) {
                        this.setAttribute("", index)
                    } else {
                        this.setAttribute(l10n.localized(null), index)
                    }
                }}
            />
        </td>
    }

    setAttribute(value: string, index: number) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        clone[index].optionId = value
        this.setState({ attributeSelection: clone })
    }

    setComparison(value: Comparison | undefined, index: number) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        clone[index].comparison = value
        this.setState({ attributeSelection: clone })
    }

    removeAttribute(index: number) {
        const clone = _.cloneDeep(this.state.attributeSelection)
        clone.splice(index)
        this.setState({ attributeSelection: clone })
    }

    render() {
        return (
            <Modal size="lg" show={true} enforceFocus={false} /* NOTE: without enforceFocus=false, text input in overlays of the modal cannot be focused */>
                <Modal.Header>
                    <Modal.Title>
                        {this.props.mode === "stock_count_percentage" ? "Stock count percentage report" : "Last counted stock report"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageState loading={!this.state.loaded} typeName="stock report">
                        <div style={{ maxWidth: 625, marginLeft: "auto", marginRight: "auto" }}>
                            <Card className="mb-4"><Card.Body>
                                <Card.Title>Report date</Card.Title>
                                <LabelledControl>
                                    <div>
                                        <Form.Text>{ this.props.mode === "stock_count_percentage" ? `The report will show the percentage of products that have ${this.props.reportType == "not_counted_since_date" ? "NOT" : ""} been counted after the selected date` : `The report will show the products that have ${this.props.reportType == "not_counted_since_date" ? "NOT" : ""} been counted after the selected date`}<br /></Form.Text>
                                        <Calendar
                                            date={this.state.filterDate}
                                            maxDate={new Date()}
                                            minDate={dayjs().subtract(18, "months").toDate()}
                                            onChange={(date) => {
                                                this.setState({ filterDate: date })
                                            }}
                                        />

                                    </div>
                                </LabelledControl>
                            </Card.Body>
                            </Card>
                            <Card className="mb-4"><Card.Body>
                                <Card.Title>Filters</Card.Title>

                                <LabelledControl>
                                    <div>
                                        <Form.Check // prettier-ignore
                                            type="checkbox"
                                            id={`default-checkbox`}
                                            label={`Exclude products with a stock count of 0`}
                                            onChange={(val) => { this.setState({ excludeZeroStockProducts: val.target.checked }) }}
                                        />
                                    </div>
                                </LabelledControl>

                                <LabelledControl label="Products">

                                    <div>
                                        <Form.Text>Filter products based on their properties</Form.Text>

                                        {this.state.attributeSelection.length > 0 &&
                                            <StripedTable>
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    {this.state.attributeSelection.map((attributeSelection, index) => {
                                                        const selectedAttribute = attributeSelection.attributeId
                                                        const attribute = this.attributesObserver.attributesDict?.[selectedAttribute]

                                                        let attributeName = attributeSelection.attributeId
                                                        if (!_.isNil(attribute)) {
                                                            attributeName = attribute.name.localized(null)
                                                        }
                                                        let type: AttributeTypeKey | undefined = undefined
                                                        if (!_.isNil(attribute)) {
                                                            type = attribute.typeKey()
                                                        }
                                                        if (!_.isNil(attribute) && !_.isNil(type)) {
                                                            let renderedValue: JSX.Element = <br />
                                                            switch (type) {
                                                                case AttributeTypeKey.NUMBER:
                                                                    break

                                                                case AttributeTypeKey.OPTIONS:
                                                                    renderedValue = this.renderOptionsValue(attribute.type.options!, attributeSelection.optionId, index)
                                                                    break

                                                                case AttributeTypeKey.TEXT:
                                                                    renderedValue = this.renderTextValue(attributeSelection.optionId, index)
                                                                    break

                                                                case AttributeTypeKey.TEXT_ENTRY:
                                                                    break

                                                                case AttributeTypeKey.DATE:
                                                                    renderedValue = this.renderDateValue(selectedAttribute, attributeSelection.optionId, index)
                                                                    break

                                                                case AttributeTypeKey.DATE_TIME:
                                                                    renderedValue = this.renderDateTimeValue(selectedAttribute, attributeSelection.optionId, index)
                                                                    break
                                                            }

                                                            return (
                                                                <tr key={selectedAttribute}>
                                                                    <td>{attributeName}</td>
                                                                    {renderedValue}
                                                                    <td className="narrow">
                                                                        <Button variant="link" onClick={() => { this.removeAttribute(index) }}><DeleteButtonSymbol /></Button>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        } else {
                                                            return <></>
                                                        }
                                                    })}
                                                </tbody>
                                            </StripedTable>
                                        }

                                        <Dropdown onSelect={item => {
                                            if (!_.isNil(item)) {
                                                this.selectAttribute(item)
                                            }
                                        }}>
                                            <Dropdown.Toggle variant="outline-primary" size="sm">Select one or more attributes</Dropdown.Toggle>
                                            <Dropdown.Menu style={{ overflowY: "scroll", maxHeight: 400 }}>
                                                {(this.attributesObserver.attributesArray ?? []).map(attribute => {
                                                    if (attribute.type.options === undefined &&
                                                        attribute.type.text === undefined &&
                                                        attribute.type.date === undefined &&
                                                        attribute.type.date_time === undefined) {
                                                        return undefined
                                                    }

                                                    return <Dropdown.Item key={attribute.id} eventKey={attribute.id}>{`${attribute.name.localized(LanguageCode.da)}`}</Dropdown.Item>
                                                })}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </LabelledControl>

                            </Card.Body></Card>
                            <LabelledControl label="Description">
                                <Form.Text>{this.filterDescription().map((line, index) => { return <p dangerouslySetInnerHTML={{ __html: line }} key={index} /> })}</Form.Text>
                            </LabelledControl>
                        </div>
                    </PageState>
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={() => { this.cancelButtonClicked() }}>Cancel</Button>
                    <Button size="sm" onClick={() => { this.openButtonClicked() }} disabled={!this.openButtonEnabled()}>{"Create report"} </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
